import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ListGroup, Row, Col, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import queryString from "query-string";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
  listManuals,
  listReportedManuals,
  listUnapprovedManuals,
} from "../actions/manualActions";
import ManualCard from "../components/ManualCard";
import Meta from "../components/Meta";
import PaginateManuals from "../components/PaginateManuals";

export default function ProfileScreen({ history, location }) {
  const dispatch = useDispatch();

  const pageNumber = location.search
    ? queryString.parse(location.search).pageNumber
    : 1;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const manualUnapprovedList = useSelector(
    (state) => state.manualUnapprovedList
  );
  const {
    loading: loadingUnapprovedManuals,
    error: errorUnapprovedManuals,
    manuals: unapprovedManuals,
    pages: pagesUnapprovedManuals,
  } = manualUnapprovedList;
  const manualReportedList = useSelector((state) => state.manualReportedList);
  const {
    loading: loadingReportedManuals,
    error: errorReportedManuals,
    manuals: reportedManuals,
    pages: pagesReportedManuals,
  } = manualReportedList;
  const manualList = useSelector((state) => state.manualList);
  const {
    loading: loadingManuals,
    error: errorManuals,
    manuals,
    page,
    pages,
  } = manualList;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      dispatch(listUnapprovedManuals());
      dispatch(listManuals({ user: userInfo._id, pageNumber }));
      if (userInfo.isAdmin) {
        dispatch(listReportedManuals());
      }
    }
  }, [dispatch, history, pageNumber, userInfo]);

  return (
    <Row>
      <Meta
        title="My Profile | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      <Col xl={3} lg={12} className="py-3 px-10">
        <h4>My Profile:</h4>
        {userInfo && (
          <ListGroup variant="flush">
            <ListGroup.Item>{userInfo.name}</ListGroup.Item>
            <ListGroup.Item>{userInfo.email}</ListGroup.Item>
            <ListGroup.Item>
              <Link className="btn btn-primary" to="/forgotpass">
                Change Password
              </Link>
            </ListGroup.Item>
          </ListGroup>
        )}
      </Col>
      <Col xl={9} lg={12} className="py-3 px-10">
        <h4>My Posts:</h4>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Link to="/updatemanual">
              <h5>My latest unfinished (draft) post...</h5>
            </Link>
          </ListGroup.Item>
          {loadingReportedManuals && <Loader />}
          {errorReportedManuals && (
            <Message variant="danger">{errorReportedManuals}</Message>
          )}
          {userInfo &&
            userInfo.isAdmin &&
            reportedManuals &&
            reportedManuals.length > 0 && (
              <ListGroup.Item>
                <h5>
                  <Image src="/images/caution_pdf.png" width="24px" /> Problem
                  Reports{" "}
                  {pagesReportedManuals > 1 && <span>(latest 20)</span>}:
                </h5>
                {reportedManuals.map((manual) => (
                <Link key={manual._id} to={`/manual/${manual._id}`}>
                  <p>{manual.name ? manual.name : "No name"}</p>
                </Link>
              ))}
              </ListGroup.Item>
            )}
          {loadingUnapprovedManuals && <Loader />}
          {errorUnapprovedManuals && (
            <Message variant="danger">{errorUnapprovedManuals}</Message>
          )}
          {unapprovedManuals && unapprovedManuals.length > 0 && (
            <ListGroup.Item>
              <h5>
                Posts waiting for approval{" "}
                {pagesUnapprovedManuals > 1 && <span>(latest 20)</span>}:
              </h5>
              {unapprovedManuals.map((manual) => (
                <Link key={manual._id} to={`/manual/${manual._id}`}>
                  <p>{manual.name ? manual.name : "No name"}</p>
                </Link>
              ))}
            </ListGroup.Item>
          )}

          <ListGroup.Item>
            {loadingManuals ? (
              <Loader />
            ) : errorManuals ? (
              <Message variant="danger">{errorManuals}</Message>
            ) : (
              <>
                <h5>Published posts:</h5>
                <Row>
                  {manuals.length === 0 && (
                    <Message variant="info">
                      You don't have any published posts yet.
                    </Message>
                  )}
                  {manuals.map((manual) => (
                    <Col key={manual._id} md={12} lg={6}>
                      <ManualCard manual={manual} />
                    </Col>
                  ))}
                </Row>
                <PaginateManuals
                  page={page}
                  pages={pages}
                  user={userInfo._id}
                />
              </>
            )}
          </ListGroup.Item>
        </ListGroup>
      </Col>
    </Row>
  );
}

import React from "react";
import { Helmet } from "react-helmet";

export default function Meta({ title, description, keywords }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="descritpion" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}
Meta.defaultProps = {
  title: "Wheels And Motors",
  descritpion: "Free online automotive repair manuals",
  keywords: "car, motorbike, free, online, workshop, repair, manuals",
};

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const USER_ACTIVATE_REQUEST = "USER_ACTIVATE_REQUEST";
export const USER_ACTIVATE_SUCCESS = "USER_ACTIVATE_SUCCESS";
export const USER_ACTIVATE_FAIL = "USER_ACTIVATE_FAIL";
export const USER_ACTIVATE_RESET = "USER_ACTIVATE_RESET";

export const USER_PASSWORD_FORGOT_REQUEST = "USER_PASSWORD_FORGOT_REQUEST";
export const USER_PASSWORD_FORGOT_SUCCESS = "USER_PASSWORD_FORGOT_SUCCESS";
export const USER_PASSWORD_FORGOT_FAIL = "USER_PASSWORD_FORGOT_FAIL";
export const USER_PASSWORD_FORGOT_RESET = "USER_PASSWORD_FORGOT_RESET";

export const USER_PASSWORD_RESET_REQUEST = "USER_PASSWORD_RESET_REQUEST";
export const USER_PASSWORD_RESET_SUCCESS = "USER_PASSWORD_RESET_SUCCESS";
export const USER_PASSWORD_RESET_FAIL = "USER_PASSWORD_RESET_FAIL";
export const USER_PASSWORD_RESET_RESET = "USER_PASSWORD_RESET_RESET";

export const USER_RESEND_EMAIL_REQUEST = "USER_RESEND_EMAIL_REQUEST";
export const USER_RESEND_EMAIL_SUCCESS = "USER_RESEND_EMAIL_SUCCESS";
export const USER_RESEND_EMAIL_FAIL = "USER_RESEND_EMAIL_FAIL";
export const USER_RESEND_EMAIL_RESET = "USER_RESEND_EMAIL_RESET";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_RESET = "USER_DELETE_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_PDF_PAYMENT_URL_REQUEST = "USER_PDF_PAYMENT_URL_REQUEST";
export const USER_PDF_PAYMENT_URL_SUCCESS = "USER_PDF_PAYMENT_URL_SUCCESS";
export const USER_PDF_PAYMENT_URL_FAIL = "USER_PDF_PAYMENT_URL_FAIL";
export const USER_PDF_PAYMENT_URL_RESET = "USER_PDF_PAYMENT_URL_RESET";

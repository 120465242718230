import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ManualListScreen from "./screens/ManualListScreen";
import ManualScreen from "./screens/ManualScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import EmailActivationScreen from "./screens/EmailActivationScreen";
import ForgotPassScreen from "./screens/ForgotPassScreen";
import ResendEmailScreen from "./screens/ResendEmailScreen";
import ProfileScreen from "./screens/ProfileScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import VehicleEditScreen from "./screens/VehicleEditScreen";
import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import FindManualScreen from "./screens/FindManualScreen";
import UpdateManualScreen from "./screens/UpdateManualScreen";
import ReportProblemScreen from "./screens/ReportProblemScreen";
import ContactFormScreen from "./screens/ContactFormScreen";
import ContactListScreen from "./screens/ContactListScreen";
import ContactDetailsScreen from "./screens/ContactDetailsScreen";
import TermsScreen from "./screens/TermsScreen";
import AboutScreen from "./screens/AboutScreen";
import PdfAccessChoise from "./screens/PdfAccessChoise";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <main className="py-3">
        <Container>
          <Route path="/" component={HomeScreen} exact />
          <Route path="/login" component={LoginScreen} />
          <Route path="/register" component={RegisterScreen} />
          <Route path="/contact" component={ContactFormScreen} />
          <Route path="/terms-and-conditions" component={TermsScreen} />
          <Route path="/about" component={AboutScreen} />
          <PrivateRoute path="/profile" component={ProfileScreen} />
          <PrivateRoute path="/pdf-access-choice" component={PdfAccessChoise} />
          <Route path="/resendcode" component={ResendEmailScreen} />
          <Route
            path="/authentication/activate/:token?"
            component={EmailActivationScreen}
          />
          <Route path="/forgotpass/:token?" component={ForgotPassScreen} />
          <Route path="/findmanual" component={FindManualScreen} />
          <Route path="/manuals" component={ManualListScreen} exact />
          <Route path="/manual/:id" component={ManualScreen} />
          <Route path="/reportproblem/:id" component={ReportProblemScreen} />
          <PrivateRoute
            path="/updatemanual/:id?"
            component={UpdateManualScreen}
          />
          <AdminRoute path="/admin/userlist" component={UserListScreen} />
          <AdminRoute path="/admin/user/:id/edit" component={UserEditScreen} />
          <AdminRoute path="/admin/vehiclelist" component={VehicleEditScreen} />
          <AdminRoute path="/admin/contactlist" component={ContactListScreen} />
          <AdminRoute
            path="/admin/contactdetails/:id"
            component={ContactDetailsScreen}
          />
        </Container>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

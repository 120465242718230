import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import queryString from "query-string";
import ManualCard from "../components/ManualCard";
import { listManuals } from "../actions/manualActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Meta from "../components/Meta";
import PaginateManuals from "../components/PaginateManuals";
import { Link } from "react-router-dom";

export default function ManualListScreen({ location }) {
  const dispatch = useDispatch();

  const make = location.search ? queryString.parse(location.search).make : "";
  const model = location.search ? queryString.parse(location.search).model : "";
  const year = location.search
    ? Number(queryString.parse(location.search).year)
    : 0;
  const category = location.search
    ? queryString.parse(location.search).category
    : "";
  const keyword = location.search
    ? queryString.parse(location.search).keyword
    : "";
  const pageNumber = location.search
    ? queryString.parse(location.search).pageNumber
    : 1;

  const manualList = useSelector((state) => state.manualList);
  const { loading, error, manuals, page, pages } = manualList;

  useEffect(() => {
    dispatch(listManuals({ make, model, year, category, keyword, pageNumber }));
  }, [category, dispatch, keyword, make, model, pageNumber, year]);

  return (
    <>
      <h3>Here is what we have:</h3>
      {(make || model || year || category || keyword) && (
        <h4>
          ({make} {model} {year !== 0 && year} {category} {keyword})
        </h4>
      )}
      <Meta
        title="Search Results | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          {manuals.length === 0 && (
            <Message variant="warning">Can't find results for that one, sorry... Try to rephrase you search keywords, if that doesn't help - probably we don't have it yet.</Message>
          )}
          <Row>
            {manuals.map((manual) => (
              <Col key={manual._id} sm={12} md={6} lg={4} xl={3}>
                <ManualCard manual={manual} />
              </Col>
            ))}
          </Row>
          <PaginateManuals
            page={page}
            pages={pages}
            make={make}
            model={model}
            year={year}
            category={category}
            keyword={keyword}
          />
        </>
      )}
      <hr />
      <Link to="/findmanual" className="btn btn-primary">
        Search Again
      </Link>
    </>
  );
}

import React, { useEffect } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  approveManual,
  deleteManual,
  deleteManualReport,
  disapproveManual,
  fetchLinkData,
  getManual,
} from "../actions/manualActions";
import Meta from "../components/Meta";
import Loader from "../components/Loader";
import Message from "../components/Message";
import PDFgenerator from "../components/PDFgenerator";
import {
  MANUAL_APPROVE_RESET,
  MANUAL_DELETE_REPORT_RESET,
  MANUAL_DELETE_RESET,
  MANUAL_DETAILS_RESET,
  MANUAL_DISAPPROVE_RESET,
  MANUAL_LINK_META_RESET,
} from "../constants/manualConstants";
import { Link } from "react-router-dom";
import { refreshUser } from "../actions/userActions";

export default function ManualScreen({ history, match }) {
  const dispatch = useDispatch();

  const manualId = match.params.id;

  const manualDetails = useSelector((state) => state.manualDetails);
  const { loading, error, manual } = manualDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { loading: loadingUser, error: errorUser, userInfo } = userLogin;

  const manualDelete = useSelector((state) => state.manualDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = manualDelete;
  const manualApprove = useSelector((state) => state.manualApprove);
  const {
    loading: loadingApprove,
    error: errorApprove,
    success: successApprove,
  } = manualApprove;
  const manualDisapprove = useSelector((state) => state.manualDisapprove);
  const {
    loading: loadingDisapprove,
    error: errorDisapprove,
    success: successDisapprove,
  } = manualDisapprove;
  const manualDeleteReport = useSelector((state) => state.manualDeleteReport);
  const {
    loading: loadingDeleteReport,
    error: errorDeleteReport,
    success: successDeleteReport,
  } = manualDeleteReport;
  const manualLink = useSelector((state) => state.manualLink);
  const {
    loading: loadingManualLink,
    error: errorManualLink,
    linkData,
  } = manualLink;

  useEffect(() => {
    if (
      (manual && manual._id && manual._id !== manualId) ||
      (manual && !manual._id)
    ) {
      dispatch(getManual(manualId));
    }

    if (successDelete) {
      dispatch({ type: MANUAL_DELETE_RESET });
      history.push("/profile");
    }
    if (successDeleteReport) {
      dispatch({ type: MANUAL_DELETE_REPORT_RESET });
    }
    if (successApprove || successDisapprove) {
      dispatch({ type: MANUAL_APPROVE_RESET });
      dispatch({ type: MANUAL_DISAPPROVE_RESET });
      dispatch({ type: MANUAL_DETAILS_RESET });
    }
    //help to prevent to send meta reuest too early with previous manual details
    if (manual && manual.website && manual._id === manualId) {
      dispatch({ type: MANUAL_LINK_META_RESET });
      dispatch(fetchLinkData(manual.website));
    }
  }, [
    dispatch,
    history,
    manualId,
    successDelete,
    successApprove,
    successDisapprove,
    successDeleteReport,
    manual,
  ]);

  //update user details in case is PDF access changes, should work with non-logged in user, that's why eslint disabled and empty array left.
  useEffect(() => {
    if (userInfo?.token) {
      dispatch(refreshUser(userInfo.token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteHandler = (id, name) => {
    if (window.confirm("Delete " + name + "? Are you sure?")) {
      dispatch(deleteManual(id));
    }
  };
  const approveHandler = (id, name) => {
    if (window.confirm("Approve " + name + "?")) {
      dispatch({ type: MANUAL_DETAILS_RESET });
      dispatch(approveManual(id));
    }
  };
  const disapproveHandler = (id, name) => {
    if (window.confirm("Disapprove " + name + "?")) {
      dispatch({ type: MANUAL_DETAILS_RESET });
      dispatch(disapproveManual(id));
    }
  };
  const deleteReportHandler = (id, report) => {
    dispatch(deleteManualReport(id, report));
    dispatch({ type: MANUAL_DETAILS_RESET });
  };

  const youTubeVideoIdExtract = (url) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  };

  const pdfAccessTimeLeft = (timeMilliSeconds) => {
    const minutes = Math.floor((timeMilliSeconds / (1000 * 60)) % 60);
    const hours = Math.floor((timeMilliSeconds / (1000 * 60 * 60)) % 24);
    return "**Access time left " + hours + " hours " + minutes + " minutes";
  };

  return (
    <>
      {loadingUser && <Loader />}
      {errorUser && <Message variant="danger">{errorUser}</Message>}
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loadingApprove && <Loader />}
      {errorApprove && <Message variant="danger">{errorApprove}</Message>}
      {loadingDisapprove && <Loader />}
      {errorDisapprove && <Message variant="danger">{errorDisapprove}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Meta
            title={`${manual.name} ${manual.make} ${manual.model} | Wheels And Motors`}
            description={`${manual.make} ${manual.model} - ${manual.description}`}
            keywords={`${manual.make}, ${manual.model}`}
          />
          {!manual.approved && (
            <h4 className="orange">...waiting for approval</h4>
          )}
          <h2>{manual.name}</h2>
          <p>
            <strong>
              {manual.make} {manual.model}
            </strong>{" "}
            {manual.yearFrom !== 0 && manual.yearFrom}{" "}
            {manual.yearFrom !== 0 && manual.yearTo !== 0 && <span>-</span>}{" "}
            {manual.yearTo !== 0 && manual.yearTo}
            <br />
            {manual.details}
          </p>
          <p>
            <Image
              src="/images/caution_pdf.png"
              alt="disclaimer"
              width="25px"
            />
            <strong>
              Caution! These instructions are created by random users and must
              be used as a reference only! All actions must be performed in
              accordance with Health and Safety regulations. Please, take all
              safety precautions, and if you're not sure - don't act!
              Administration will not accept any responsibility for damage to
              you or your vehicle after following these instructions bellow.
            </strong>
          </p>

          <div className="center">
            {userInfo && (userInfo.isAdmin || userInfo.isCreator) ? (
              <>
                <Button variant="success" onClick={() => PDFgenerator(manual)}>
                  <i className="fas fa-print"></i> Download this manual in PDF*
                </Button>
                <p>
                  * Please note - PDF files may take some time to generate...
                  Please be patient.
                </p>
              </>
            ) : userInfo &&
              new Date(userInfo.paidTill).getTime() > Date.now() ? (
              <>
                <Button variant="success" onClick={() => PDFgenerator(manual)}>
                  <i className="fas fa-print"></i> Download this manual in PDF*
                </Button>
                <p>
                  * Please note - PDF files may take some time to generate...
                  Please be patient.
                  <br />
                  <b>
                    {pdfAccessTimeLeft(
                      new Date(userInfo.paidTill).getTime() - Date.now(),
                    )}
                  </b>
                </p>
              </>
            ) : userInfo ? (
              <>
                <Link
                  className="btn btn-info"
                  to={`/pdf-access-choice?m=${manual._id}`}
                >
                  <i className="fas fa-print"></i> Download this manual in PDF*
                </Link>
                <p>* Conditions apply.</p>
              </>
            ) : (
              <>
                <Link className="btn btn-info" to={"/login"}>
                  <i className="fas fa-print"></i> Download this manual in PDF*
                </Link>
                <p>* Registered users only. Conditions apply.</p>
              </>
            )}
          </div>
          <Row>
            <Col lg={6}>
              <h3>Images:</h3>
              <Carousel className="bg-dark" interval={1000000000}>
                {manual.images ? (
                  manual.images.map((image) => (
                    <Carousel.Item key={image.url}>
                      <Image src={image.url} alt={image.caption} fluid />
                      <Carousel.Caption className="carousel-caption">
                        <h5>{image.caption}</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))
                ) : (
                  <Carousel.Item>
                    <Carousel.Caption className="carousel-caption">
                      <h5>No images uploaded for this manual.</h5>
                    </Carousel.Caption>
                  </Carousel.Item>
                )}
              </Carousel>
            </Col>
            <Col lg={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h4>How we do it:</h4>
                  <div className="manual-textarea">{manual.text}</div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h4>Tools for this job:</h4>
                  {manual.tools}
                </ListGroup.Item>
                <ListGroup.Item>
                  <h4>
                    <Image src="/images/play_button.png" width="33px" /> Watch
                    it on YouTube:
                  </h4>
                  {manual.youTubeLink ? (
                    <a
                      href={manual.youTubeLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src={
                          "https://img.youtube.com/vi/" +
                          youTubeVideoIdExtract(manual.youTubeLink) +
                          "/hqdefault.jpg"
                        }
                        width="60%"
                        className="center"
                      />
                    </a>
                  ) : (
                    <p>No link provided...</p>
                  )}
                </ListGroup.Item>
                {manual.website && (
                  <ListGroup.Item>
                    <h4>Special Thanks to:</h4>
                    {loadingManualLink ? (
                      <Loader />
                    ) : errorManualLink ? (
                      <Card style={{ width: "60%" }}>
                        <Card.Text>
                          <Link
                            to={{ pathname: manual.website }}
                            target="_blank"
                          >
                            <strong>{manual.website}</strong>
                          </Link>
                        </Card.Text>
                      </Card>
                    ) : (
                      linkData && (
                        <Card style={{ width: "60%" }}>
                          <Link
                            to={{
                              pathname: linkData.url
                                ? linkData.url
                                : manual.website,
                            }}
                            target="_blank"
                          >
                            <Card.Img variant="top" src={linkData.image} />
                          </Link>
                          <Card.Body>
                            <Card.Title>
                              <Link
                                to={{
                                  pathname: linkData.url
                                    ? linkData.url
                                    : manual.website,
                                }}
                                target="_blank"
                              >
                                {linkData.title}
                              </Link>
                            </Card.Title>
                            <Card.Text>{linkData.description}</Card.Text>
                            <Card.Text>
                              <Link
                                to={{
                                  pathname: linkData.url
                                    ? linkData.url
                                    : manual.website,
                                }}
                                target="_blank"
                              >
                                <strong>
                                  {linkData.url ? linkData.url : manual.website}
                                </strong>
                              </Link>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      )
                    )}
                  </ListGroup.Item>
                )}
                <ListGroup.Item>
                  <Link to={`/reportproblem/${manual._id}`}>
                    <h4>
                      <i className="fas fa-exclamation-circle"></i> Report a
                      problem with this post
                    </h4>
                  </Link>
                </ListGroup.Item>
                {userInfo &&
                  (userInfo.isAdmin || userInfo._id === manual.user) && (
                    <>
                      <ListGroup.Item>
                        <Link to={`/updatemanual/${manual._id}`}>
                          <h4>
                            <i className="fas fa-edit"></i> Make an Edit
                          </h4>
                        </Link>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div
                          className="link-like"
                          onClick={() => deleteHandler(manual._id, manual.name)}
                        >
                          <h4>
                            <i className="fas fa-times"></i> Delete this Manual
                          </h4>
                        </div>
                      </ListGroup.Item>
                    </>
                  )}
                {userInfo && userInfo.isAdmin && (
                  <>
                    <ListGroup.Item>
                      {manual.approved ? (
                        <Button
                          variant="warning"
                          onClick={() =>
                            disapproveHandler(manual._id, manual.name)
                          }
                        >
                          <i className="fas fa-check"></i> Disapprove this
                          Manual
                        </Button>
                      ) : (
                        <Button
                          variant="success"
                          onClick={() =>
                            approveHandler(manual._id, manual.name)
                          }
                        >
                          <i className="fas fa-check"></i> Approve this Manual
                        </Button>
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Link to={`/admin/user/${manual.user}/edit`}>
                        Contributing Members Profile
                      </Link>
                    </ListGroup.Item>
                  </>
                )}
                {userInfo && userInfo.isAdmin && (
                  <ListGroup.Item>
                    {manual.numReports > 0 ? (
                      <div>
                        <h4>
                          <Image src="/images/caution_pdf.png" width="24px" />{" "}
                          Problem Reports:
                        </h4>
                        <br />
                        <ListGroup>
                          {loadingDeleteReport && <Loader />}
                          {errorDeleteReport && (
                            <Message variant="danger">
                              {errorDeleteReport}
                            </Message>
                          )}
                          {manual.reports.map((report) => (
                            <ListGroup.Item key={report}>
                              <Card>
                                <Card.Body>
                                  {report}
                                  <Button
                                    variant="secondary"
                                    size="sm"
                                    className="float-right m-3"
                                    onClick={() =>
                                      deleteReportHandler(manualId, report)
                                    }
                                  >
                                    Clear
                                  </Button>
                                </Card.Body>
                              </Card>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </div>
                    ) : (
                      <h4>No problem reports here...</h4>
                    )}
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export const MANUAL_LIST_REQUEST = "MANUAL_LIST_REQUEST";
export const MANUAL_LIST_SUCCESS = "MANUAL_LIST_SUCCESS";
export const MANUAL_LIST_FAIL = "MANUAL_LIST_FAIL";

export const MANUAL_DETAILS_REQUEST = "MANUAL_DETAILS_REQUEST";
export const MANUAL_DETAILS_SUCCESS = "MANUAL_DETAILS_SUCCESS";
export const MANUAL_DETAILS_FAIL = "MANUAL_DETAILS_FAIL";
export const MANUAL_DETAILS_RESET = "MANUAL_DETAILS_RESET";

export const MANUAL_CREATE_REQUEST = "MANUAL_CREATE_REQUEST";
export const MANUAL_CREATE_SUCCESS = "MANUAL_CREATE_SUCCESS";
export const MANUAL_CREATE_FAIL = "MANUAL_CREATE_FAIL";
export const MANUAL_CREATE_RESET = "MANUAL_CREATE_RESET";

export const MANUAL_UPDATE_REQUEST = "MANUAL_UPDATE_REQUEST";
export const MANUAL_UPDATE_SUCCESS = "MANUAL_UPDATE_SUCCESS";
export const MANUAL_UPDATE_FAIL = "MANUAL_UPDATE_FAIL";
export const MANUAL_UPDATE_RESET = "MANUAL_UPDATE_RESET";

export const MANUAL_DELETE_REQUEST = "MANUAL_DELETE_REQUEST";
export const MANUAL_DELETE_SUCCESS = "MANUAL_DELETE_SUCCESS";
export const MANUAL_DELETE_FAIL = "MANUAL_DELETE_FAIL";
export const MANUAL_DELETE_RESET = "MANUAL_DELETE_RESET";

export const MANUAL_APPROVE_REQUEST = "MANUAL_APPROVE_REQUEST";
export const MANUAL_APPROVE_SUCCESS = "MANUAL_APPROVE_SUCCESS";
export const MANUAL_APPROVE_FAIL = "MANUAL_APPROVE_FAIL";
export const MANUAL_APPROVE_RESET = "MANUAL_APPROVE_RESET";

export const MANUAL_DISAPPROVE_REQUEST = "MANUAL_DISAPPROVE_REQUEST";
export const MANUAL_DISAPPROVE_SUCCESS = "MANUAL_DISAPPROVE_SUCCESS";
export const MANUAL_DISAPPROVE_FAIL = "MANUAL_DISAPPROVE_FAIL";
export const MANUAL_DISAPPROVE_RESET = "MANUAL_DISAPPROVE_RESET";

export const MANUAL_UNAPPROVED_LIST_REQUEST = "MANUAL_UNAPPROVED_LIST_REQUEST";
export const MANUAL_UNAPPROVED_LIST_SUCCESS = "MANUAL_UNAPPROVED_LIST_SUCCESS";
export const MANUAL_UNAPPROVED_LIST_FAIL = "MANUAL_UNAPPROVED_LIST_FAIL";

export const MANUAL_REPORT_PROBLEM_REQUEST = "MANUAL_REPORT_PROBLEM_REQUEST";
export const MANUAL_REPORT_PROBLEM_SUCCESS = "MANUAL_REPORT_PROBLEM_SUCCESS";
export const MANUAL_REPORT_PROBLEM_FAIL = "MANUAL_REPORT_PROBLEM_FAIL";
export const MANUAL_REPORT_PROBLEM_RESET = "MANUAL_REPORT_PROBLEM_RESET";

export const MANUAL_REPORTED_LIST_REQUEST = "MANUAL_REPORTED_LIST_REQUEST";
export const MANUAL_REPORTED_LIST_SUCCESS = "MANUAL_REPORTED_LIST_SUCCESS";
export const MANUAL_REPORTED_LIST_FAIL = "MANUAL_REPORTED_LIST_FAIL";

export const MANUAL_DELETE_REPORT_REQUEST = "MANUAL_DELETE_REPORT_REQUEST";
export const MANUAL_DELETE_REPORT_SUCCESS = "MANUAL_DELETE_REPORT_SUCCESS";
export const MANUAL_DELETE_REPORT_FAIL = "MANUAL_DELETE_REPORT_FAIL";
export const MANUAL_DELETE_REPORT_RESET = "MANUAL_DELETE_REPORT_RESET";

export const MANUAL_LINK_META_REQUEST = "MANUAL_LINK_META_REQUEST";
export const MANUAL_LINK_META_SUCCESS = "MANUAL_LINK_META_SUCCESS";
export const MANUAL_LINK_META_FAIL = "MANUAL_LINK_META_FAIL";
export const MANUAL_LINK_META_RESET = "MANUAL_LINK_META_RESET";

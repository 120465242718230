import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import {
  logout,
  requestPasswordReset,
  resetPassword,
} from "../actions/userActions";
import {
  USER_PASSWORD_FORGOT_RESET,
  USER_PASSWORD_RESET_RESET,
} from "../constants/userConstants";
import Meta from "../components/Meta";

export default function ForgotPassScreen({ history, match }) {
  const dispatch = useDispatch();

  const resetToken = match.params.token ? match.params.token : null;

  const forgotPassword = useSelector((state) => state.forgotPassword);
  const {
    loading: loadingForgot,
    error: errorForgot,
    success: successForgot,
  } = forgotPassword;

  const passwordReset = useSelector((state) => state.passwordReset);
  const {
    loading: loadingReset,
    error: errorReset,
    success: successReset,
  } = passwordReset;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (successForgot) {
      dispatch(logout());
    }
    if (resetToken) {
      dispatch({ type: USER_PASSWORD_FORGOT_RESET });
    }
    if (successReset) {
      if (window.confirm("Your password was successfully reset.")) {
        dispatch({ type: USER_PASSWORD_RESET_RESET });
        history.push("/");
      }
    }
  }, [dispatch, history, resetToken, successForgot, successReset]);

  const submitHandler = (e) => {
    if (!resetToken) {
      e.preventDefault();
      dispatch(requestPasswordReset(email));
    } else {
      e.preventDefault();
      if (password !== confirmPassword) {
        setMessage("Password and Confirm Password do not match");
      } else {
        dispatch(resetPassword(password, resetToken));
      }
    }
  };
  return (
    <FormContainer>
      <Meta
        title="Password Reset | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      <h1>Password Reset</h1>
      {loadingForgot && <Loader />}
      {errorForgot && <Message variant="danger">{errorForgot}</Message>}

      {loadingReset && <Loader />}
      {errorReset && <Message variant="danger">{errorReset}</Message>}
      {message && <Message variant="danger">{message}</Message>}

      {successForgot && (
        <Message variant="success">
          Thanks! Now please check your email for password reset instructions.
        </Message>
      )}

      <Form onSubmit={submitHandler}>
        {!resetToken && !successForgot ? (
          <Form.Group controlId="email">
            <Form.Label>Enter your email address:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>
        ) : (
          resetToken && (
            <div>
              <Form.Group controlId="password">
                <Form.Label>
                  Password
                  <br />
                  (min 8 characters, at least 1: Uppercase, Lowercase and
                  Number)
                </Form.Label>
                <Form.Control
                  type="password"
                  required
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  required
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </div>
          )
        )}
        {(!successForgot || resetToken) && (
          <Button type="submit" variant="primary">
            Submit
          </Button>
        )}
      </Form>
    </FormContainer>
  );
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { activateUser } from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { USER_ACTIVATE_RESET } from "../constants/userConstants";
import Meta from "../components/Meta";

export default function EmailActivationScreen({ location, match }) {
  const dispatch = useDispatch();

  const token = match.params.token ? match.params.token : null;
  const email = location.search
    ? queryString.parse(location.search).email
    : null;

  const userActivate = useSelector((state) => state.userActivate);
  const { loading, error, name } = userActivate;

  useEffect(() => {
    if (token) {
      dispatch(activateUser(token));
    } else {
      dispatch({ type: USER_ACTIVATE_RESET });
    }
  }, [dispatch, token]);
  return (
    <div>
      <Meta
        title="Activate email | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      <h2>User Account Activation:</h2>
      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      {name && (
        <>
          <h3>Welcome {name}!</h3>
          <p>Thank you for verifying your email!</p>
          <p>Your account is now active, and you can:</p>
          <Link className="btn btn-lg btn-success" to={"/login"}>
            Proceed to LOG IN
          </Link>
        </>
      )}
      {email && (
        <>
          <h3>Thank you for registering!</h3>
          <p>Now to activate your account, we need to verify your email:</p>
          <h4>{email}</h4>
          <p>
            Check your email and click (or copy-paste to your browser)
            activation link that we sent you. Please note - some email services
            may take some time to process your email.
          </p>
        </>
      )}
    </div>
  );
}

import jsPDF from "jspdf";
import "jspdf-autotable";

export default function PDFgenerator(manual) {
  // Default export is a4 paper, portrait, using millimeters for units
  const doc = new jsPDF();

  const lMargin = 15; //left margin in mm
  const rMargin = 15; //right margin in mm
  const pdfInMM = 210; // width of A4 in mm

  //disclaimer
  const warningImgData1 = "/images/caution_pdf.png";
  doc.addImage(warningImgData1, "PNG", 20, 30, 21, 18);
  const warningImgData2 = "/images/caution_pdf.png";
  doc.addImage(warningImgData2, "PNG", 169, 30, 21, 18);

  doc.setFontSize(12);
  const disclaimer = doc.splitTextToSize(
    "Caution! These instructions are created by random users and must be used as a reference only! All actions must be performed in accordance with Health and Safety regulations. Please, take all safety precautions, and if you're not sure - don't act! Administration will not accept any responsibility for damage to you or your vehicle after following these instructions bellow.",
    pdfInMM - lMargin * 3 - rMargin * 3
  );
  doc.text(105, 30, disclaimer, { align: "center" });

  //logo
  const logoImgData = "/images/logo_white_normal.jpg";
  doc.addImage(logoImgData, "JPEG", 80, 65, 48, 36);

  doc.setFontSize(32);
  const manualName = doc.splitTextToSize(
    manual.name,
    pdfInMM - lMargin - rMargin
  );
  doc.text(105, 120, manualName, { align: "center" });

  doc.setFontSize(22);
  const carDescription = doc.splitTextToSize(
    `${manual.make} ${manual.model} ${
      manual.yearFrom !== 0 ? manual.yearFrom : ""
    } ${manual.yearFrom !== 0 && manual.yearTo !== 0 ? "-" : ""} ${manual.yearTo !== 0 ? manual.yearTo : ""}`,
    pdfInMM - lMargin - rMargin
  );
  doc.text(105, 150, carDescription, { align: "center" });

  doc.setFontSize(22);
  const carDetails = doc.splitTextToSize(
    manual.details,
    pdfInMM - lMargin - rMargin
  );
  doc.text(105, 160, carDetails, { align: "center" });

  //credentials
  if (manual.youTubeLink) {
    doc.setFontSize(12);
    const youTubeLink = doc.splitTextToSize(
      `Watch it on YouTube: ${manual.youTubeLink}`,
      pdfInMM - lMargin - rMargin
    );
    doc.text(lMargin, 175, youTubeLink);
  }
  if (manual.website) {
    doc.setFontSize(12);
    const website = doc.splitTextToSize(
      `Special THANKS to: ${manual.website}`,
      pdfInMM - lMargin - rMargin
    );
    doc.text(lMargin, 180, website);
  }

  doc.setFontSize(14);
  const manualDescription = doc.splitTextToSize(
    manual.description,
    pdfInMM - lMargin - rMargin
  );
  doc.text(lMargin, 190, manualDescription);

  // Use javascript directly with autoTable
  // Tools for the job
  doc.autoTable({
    head: [["Tools we need for this job"]],
    body: [
      [manual.tools],
      // ...
    ],
    styles: { fillColor: [85, 99, 87] },
    headerStyles: { fontSize: 14 },
    bodyStyles: { fontSize: 14 },
    margin: { top: 230 },
  });

  doc.addPage();

  // Manual text:
  doc.autoTable({
    head: [["How we do it"]],
    body: [[manual.text]],
    styles: { fillColor: [85, 99, 87] },
    headerStyles: { fontSize: 14 },
    bodyStyles: { fontSize: 14 },
    margin: { top: 20 },
    // beforePageContent: function (data) {
    //   doc.setFontSize(12);
    //   doc.text(`WheelsAndMotors.com - ${manual.name}`, 15, 10);
    // },
  });

  // Images

  for (let i = 0; i < manual.images.length; i++) {
    doc.addPage();
    doc.autoTable({
      head: [["Images"]],
      body: [[manual.images[i].caption]],
      styles: { fillColor: [85, 99, 87] },
      headerStyles: { fontSize: 14 },
      bodyStyles: { fontSize: 20 },
      margin: { top: 20 },
    });

    const imgProps = doc.getImageProperties(manual.images[i].url);
    const imageWidth = 180;
    const imageHeight = (imgProps.height * imageWidth) / imgProps.width;
    doc.addImage(manual.images[i].url, "PNG", 15, 50, imageWidth, imageHeight);
  }

  // PAGE NUMBERING, add Page number at bottom-right
  // Get the number of pages
  const pageCount = doc.internal.getNumberOfPages();
  // For each page, print the page number and the total pages
  for (var i = 1; i <= pageCount; i++) {
    // Go to page i
    doc.setPage(i);
    const headerText = `WheelsAndMotors.com - ${manual.name}`;
    doc.setFontSize(12);
    doc.text(headerText, 15, 10);
    doc.text(170, 10, "Page " + String(i) + " of " + String(pageCount));
  }

  //   doc.output('dataurlnewwindow');

  //   const string = doc.output("datauristring");
  //   const embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  //   const x = window.open();
  //   x.document.open();
  //   x.document.write(embed);
  //   x.document.close();

  doc.save(`${manual.name}.pdf`);
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ListGroup } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { deleteContact, getContact } from "../actions/contactActions";
import { CONTACT_DELETE_RESET } from "../constants/contactConstants";
import Meta from "../components/Meta";
import { Link } from "react-router-dom";

export default function ContactDetailsScreen({ match, history }) {
  const dispatch = useDispatch();

  const contactId = match.params.id;

  const contactDetails = useSelector((state) => state.contactDetails);
  const { loading, error, contact } = contactDetails;
  const contactDelete = useSelector((state) => state.contactDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = contactDelete;

  useEffect(() => {
    dispatch(getContact(contactId));
    if (successDelete) {
      dispatch({ type: CONTACT_DELETE_RESET });
      history.push("/admin/contactlist");
    }
  }, [dispatch, history, contactId, successDelete]);

  const deleteHandler = (id, name) => {
    if (window.confirm("Delete message from " + name + "? Are you sure?")) {
      dispatch(deleteContact(id));
    }
  };
  return (
    <FormContainer>
      <Meta
        title="Admin - See Message | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {contact && (
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Link
              className="btn btn-info"
              to="/admin/contactlist"
            >
              Back to Contacts
            </Link>
            <Button
              variant="danger"
              className="btn mx-3 my-2"
              onClick={() => deleteHandler(contact._id, contact.name)}
            >
              <i className="fas fa-trash"></i> Delete
            </Button>
          </ListGroup.Item>
          <ListGroup.Item>{contact.name}</ListGroup.Item>
          <ListGroup.Item>{contact.email}</ListGroup.Item>
          <ListGroup.Item>{contact.message}</ListGroup.Item>
        </ListGroup>
      )}
    </FormContainer>
  );
}

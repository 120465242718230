import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  createManual,
  getManual,
  updateManual,
} from "../actions/manualActions";
import {
  MANUAL_CREATE_RESET,
  MANUAL_DETAILS_RESET,
  MANUAL_UPDATE_RESET,
} from "../constants/manualConstants";
import { Button, Card, Form } from "react-bootstrap";
import Meta from "../components/Meta";
import { listVehicles } from "../actions/vehicleActions";

export default function UpdateManualScreen({ history, match }) {
  const dispatch = useDispatch();

  const manualId = match.params.id;

  const [finished, setFinished] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [make, setMake] = useState("");
  const [models, setModels] = useState([]);
  const [model, setModel] = useState("");
  const [yearFrom, setYearFrom] = useState(0);
  const [yearTo, setYearTo] = useState(0);
  const [category, setCategory] = useState("");
  const [details, setDetails] = useState("");
  const [text, setText] = useState("");
  const [tools, setTools] = useState("");
  const [youTubeLink, setYouTubeLink] = useState("");
  const [website, setWebsite] = useState("");
  //image related states
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [imageCaption, setImageCaption] = useState("");
  const [uploadingError, setUploadingError] = useState("");

  //set array for "Select Year" dropdown menu
  const currentYear = new Date().getFullYear();
  const yearSpan = currentYear - 1900;
  const dropdownYears = Array.from(
    new Array(yearSpan),
    (val, index) => currentYear - index
  );

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const manualCreate = useSelector((state) => state.manualCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    manual: createdManual,
  } = manualCreate;
  const manualUpdate = useSelector((state) => state.manualUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    manual: updatedManual,
  } = manualUpdate;
  const manualDetails = useSelector((state) => state.manualDetails);
  const { loading: loadingManual, error: errorManual, manual } = manualDetails;
  const vehicleList = useSelector((state) => state.vehicleList);
  const {
    loading: loadingVehicles,
    error: errorVehicles,
    vehicles,
  } = vehicleList;

  useEffect(() => {
    if (!manualId) {
      dispatch(createManual());
    } else if (!manual || !manual._id || manual._id !== manualId) {
      dispatch(getManual(manualId));
    } else {
      if (!vehicles) {
        dispatch(listVehicles());
      } else {
        if (make !== "") {
          setModels(
            vehicles.find((vehicle) => vehicle.make === make).models.sort()
          );
        } else {
          setModels([]);
        }
        manual.name && setName(manual.name);
        manual.description && setDescription(manual.description);
        manual.make && setMake(manual.make);
        manual.model && setModel(manual.model);
        manual.yearFrom && setYearFrom(manual.yearFrom);
        manual.yearTo && setYearTo(manual.yearTo);
        manual.category && setCategory(manual.category);
        manual.details && setDetails(manual.details);
        manual.text && setText(manual.text);
        manual.tools && setTools(manual.tools);
        manual.images && setImages(manual.images);
        manual.youTubeLink && setYouTubeLink(manual.youTubeLink);
        manual.website && setWebsite(manual.website);
      }
    }
    if (createdManual) {
      dispatch({ type: MANUAL_CREATE_RESET });
      history.push(`/updatemanual/${createdManual._id}`);
    }
    if (updatedManual && updatedManual.finished) {
      window.alert(
        "Your manual successfully submitted for a review. It may take up to 48 hours for it to be published."
      );
      dispatch({ type: MANUAL_UPDATE_RESET });
      dispatch({ type: MANUAL_DETAILS_RESET });
      history.push(`/manual/${updatedManual._id}`);
    }
    if (updatedManual && !updatedManual.finished) {
      dispatch(getManual(updatedManual._id));
      dispatch({ type: MANUAL_UPDATE_RESET });
      window.alert("Your manual saved as a draft");
    }
  }, [
    dispatch,
    history,
    manualId,
    createdManual,
    manual,
    vehicles,
    make,
    updatedManual,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateManual({
        _id: manualId,
        finished,
        name,
        images,
        description,
        make,
        model,
        yearFrom,
        yearTo,
        category,
        details,
        text,
        tools,
        youTubeLink,
        website,
      })
    );
  };
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `/api/upload/${manualId}`,
        formData,
        config
      );
      images.push({ url: data, caption: imageCaption });
      setUploading(false);
      setUploadingError("");
      setImageCaption("");
    } catch (error) {
      setUploading(false);
      setUploadingError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };
  const deleteImageHandler = (imageUrl, imageCaption) => {
    if (window.confirm(`Delete image "${imageCaption}"?`)) {
      //remove image from array images
      const updatedImages = images.filter((image) => image.url !== imageUrl);
      setImages(updatedImages);
    }
  };
  return (
    <FormContainer>
      <Meta
        title="Create New Manual | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant="danger">{errorCreate}</Message>}
      {loadingUpdate && <Loader />}
      {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
      {loadingManual ? (
        <Loader />
      ) : errorManual ? (
        <Message variant="danger">{errorManual}</Message>
      ) : (
        <div>
          {manual && (manual.user === userInfo._id || userInfo.isAdmin) && (
            <>
              <h2>Create/Update Manual</h2>
              <p>
                By posting here you agree not to breach copyright law. Any posts
                that we believe to be breaching law will be deleted by
                administration
              </p>
              <p>
                {" "}
                * Please be aware that general vehicle repair data, such as
                torque values, part numbers, or information on how to perform
                certain work can not be copyrighted. Just try not to post images
                that could be subject to copyright or don't just copy-paste
                something. Do your best to share your knowledge and explain
                yourself.
              </p>

              {loadingVehicles && <Loader />}
              {errorVehicles && (
                <Message variant="danger">{errorVehicles}</Message>
              )}
              <Form onSubmit={submitHandler}>
                <Form.Label>Select a vehicle:</Form.Label>
                <Form.Group controlId="make">
                  <Form.Control
                    as="select"
                    value={make}
                    onChange={(e) => setMake(e.target.value)}
                    className="m-3"
                  >
                    <option value="">Select Make</option>
                    {vehicles &&
                      vehicles.map((vehicle) => (
                        <option key={vehicle._id} value={vehicle.make}>
                          {vehicle.make}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="model">
                  <Form.Control
                    as="select"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    className="m-3"
                  >
                    <option value="">Select Model</option>
                    {models &&
                      models.map((model, index) => (
                        <option key={index} value={model}>
                          {model}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="yearFrom">
                  <Form.Control
                    as="select"
                    value={yearFrom}
                    onChange={(e) => setYearFrom(e.target.value)}
                    className="m-3"
                  >
                    <option value="">Select Year - Start of Production</option>
                    {dropdownYears.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="yearTo">
                  <Form.Control
                    as="select"
                    value={yearTo}
                    onChange={(e) => setYearTo(e.target.value)}
                    className="m-3"
                  >
                    <option value="">Select Year - End of Production</option>
                    {dropdownYears.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="details">
                  <Form.Control
                    type="text"
                    maxLength={50}
                    placeholder="Any additional details (like engine or body type)"
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="category">
                  <Form.Control
                    as="select"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className="m-3"
                  >
                    <option value="">Select Category</option>
                    <option value="Powertrain">Powertrain</option>
                    <option value="Transmission/Clutch/Driveshafts">
                      Transmission/Clutch/Driveshafts
                    </option>
                    <option value="Suspension">Suspension</option>
                    <option value="Brakes">Brakes</option>
                    <option value="Steering">Steering</option>
                    <option value="Body Exterior">Body Exterior</option>
                    <option value="Body Interior">Body Interior</option>
                    <option value="Electrics/Electronics">
                      Electrics/Electronics
                    </option>
                    <option value="Software">Software</option>
                    <option value="Heating/Air Conditioning">
                      Heating/Air Conditioning
                    </option>
                  </Form.Control>
                </Form.Group>
                <Form.Label>Your manual:</Form.Label>
                <Form.Group controlId="name">
                  <Form.Control
                    type="text"
                    maxLength={50}
                    placeholder="Short name of your manual"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="description">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Describe what is it about"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="text">
                  <Form.Control
                    as="textarea"
                    rows={10}
                    placeholder="Text of your manual"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="tools">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="What tools do we need for this?"
                    value={tools}
                    onChange={(e) => setTools(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="image">
                  <Form.Label>Upload Images</Form.Label>
                  {images.map((image) => (
                    <Card key={image.url} className="my-3 p-3 rounded">
                      <Card.Header>{image.caption}</Card.Header>
                      <Card.Img src={image.url} />
                      <Button
                        variant="danger"
                        onClick={() =>
                          deleteImageHandler(image.url, image.caption)
                        }
                      >
                        <i className="fas fa-times"></i> Remove Image
                      </Button>
                    </Card>
                  ))}
                  {uploading && <Loader />}
                  {uploadingError && (
                    <Message variant="danger">{uploadingError}</Message>
                  )}
                  {images.length < 50 ? (
                    <>
                      <Form.Control
                        type="text"
                        maxLength={100}
                        placeholder="Describe next image..."
                        value={imageCaption}
                        onChange={(e) => setImageCaption(e.target.value)}
                      ></Form.Control>
                      <Form.File
                        id="image-file"
                        label="Upload an image (JPG and PNG only, max 5Mb)"
                        custom
                        onChange={uploadFileHandler}
                      ></Form.File>{" "}
                    </>
                  ) : (
                    <Message variant="info">
                      Reached maximum 50 images per manual
                    </Message>
                  )}
                </Form.Group>
                <Form.Label>Enter links that you wish to promote:</Form.Label>
                <Form.Group controlId="youTubeLink">
                  <Form.Control
                    type="text"
                    placeholder="YouTube video link"
                    value={youTubeLink}
                    onChange={(e) => setYouTubeLink(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="website">
                  <Form.Control
                    type="text"
                    placeholder="Website or YouTube channel URL"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Link
                  className="btn btn-light"
                  to={manual.approved ? `/manual/${manual._id}` : "/profile"}
                  onClick={() => dispatch({ type: MANUAL_DETAILS_RESET })}
                >
                  Cancel
                </Link>{" "}
                {manual.user === userInfo._id && (
                  <Button
                    type="submit"
                    onClick={() => setFinished(false)}
                    variant="info"
                  >
                    <i className="fas fa-edit"></i> Save as a Draft
                  </Button>
                )}{" "}
                <Button
                  type="submit"
                  onClick={() => setFinished(true)}
                  variant="success"
                >
                  <i className="fas fa-check"></i>
                  Submit for Review
                </Button>
              </Form>
            </>
          )}
          {manual && manual.user !== userInfo._id && !userInfo.isAdmin && (
            <div>
              <h3>
                This manual can only be edited by person who created it, or
                admin.
              </h3>
              <Link className="btn btn-primary" to={`/updatemanual`}>
                Go Back
              </Link>
            </div>
          )}
        </div>
      )}
    </FormContainer>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { login } from "../actions/userActions";
import {
  USER_ACTIVATE_RESET,
  USER_PASSWORD_FORGOT_RESET,
  USER_PASSWORD_RESET_RESET,
} from "../constants/userConstants";
import Meta from "../components/Meta";

export default function LoginScreen({ history }) {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    dispatch({ type: USER_ACTIVATE_RESET });
    dispatch({ type: USER_PASSWORD_FORGOT_RESET });
    dispatch({ type: USER_PASSWORD_RESET_RESET });
    if (userInfo && userInfo.confirmedEmail) {
      history.push("/");
    }
    if (userInfo && !userInfo.confirmedEmail) {
      history.push(`/authentication/activate?email=${userInfo.email}`);
    }
  }, [dispatch, history, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };
  return (
    <FormContainer>
      <Meta
        title="Sign In | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      <h1>Sign In</h1>
      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type="submit" variant="primary">
          Sign In
        </Button>
      </Form>
      <ListGroup variant="flush">
        <ListGroup.Item>
          Not a Member? <Link to="/register">Register</Link>
        </ListGroup.Item>
        <ListGroup.Item>
          Forgot your password? <Link to="/forgotpass">Password Reset</Link>
        </ListGroup.Item>
        <ListGroup.Item>
          Can't find email with activation code?{" "}
          <Link to="/resendcode">Resend Activation Link</Link>
        </ListGroup.Item>
      </ListGroup>
    </FormContainer>
  );
}

import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default function PaginateUsers({
  pages,
  page,
  searchTerm = "",
  keyword = "",
}) {
  return (
    pages > 1 && (
        <ButtonGroup className="mb-2">
        <LinkContainer
          to={
            searchTerm && keyword
              ? `/admin/userlist?searchTerm=${searchTerm}&keyword=${keyword}&pageNumber=1`
              : `/admin/userlist?pageNumber=1`
          }
        >
          <Button disabled={page === 1}>First Page</Button>
        </LinkContainer>

        <LinkContainer
          to={
            searchTerm && keyword
              ? `/admin/userlist?searchTerm=${searchTerm}&keyword=${keyword}&pageNumber=${
                  page - 1
                }`
              : `/admin/userlist?pageNumber=${page - 1}`
          }
        >
          <Button disabled={page === 1}>Previous Page</Button>
        </LinkContainer>
        <LinkContainer
          to={
            searchTerm && keyword
              ? `/admin/userlist?searchTerm=${searchTerm}&keyword=${keyword}&pageNumber=${
                  page + 1
                }`
              : `/admin/userlist?pageNumber=${page + 1}`
          }
        >
          <Button disabled={page === pages}>Next Page</Button>
        </LinkContainer>
        <LinkContainer
          to={
            searchTerm && keyword
              ? `/admin/userlist?searchTerm=${searchTerm}&keyword=${keyword}&pageNumber=${pages}`
              : `/admin/userlist?pageNumber=${pages}`
          }
        >
          <Button disabled={page === pages}>Last Page</Button>
        </LinkContainer>
      </ButtonGroup>
    )
  );
}

import {
  USER_ACTIVATE_FAIL,
  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_RESET,
  USER_ACTIVATE_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PASSWORD_FORGOT_FAIL,
  USER_PASSWORD_FORGOT_REQUEST,
  USER_PASSWORD_FORGOT_RESET,
  USER_PASSWORD_FORGOT_SUCCESS,
  USER_PASSWORD_RESET_FAIL,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_RESET,
  USER_PASSWORD_RESET_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_RESET,
  USER_REGISTER_SUCCESS,
  USER_RESEND_EMAIL_FAIL,
  USER_RESEND_EMAIL_REQUEST,
  USER_RESEND_EMAIL_RESET,
  USER_RESEND_EMAIL_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,
  USER_PDF_PAYMENT_URL_REQUEST,
  USER_PDF_PAYMENT_URL_SUCCESS,
  USER_PDF_PAYMENT_URL_FAIL,
  USER_PDF_PAYMENT_URL_RESET,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, success: true };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const userActivateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIVATE_REQUEST:
      return { loading: true };
    case USER_ACTIVATE_SUCCESS:
      return { loading: false, name: action.payload };
    case USER_ACTIVATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_ACTIVATE_RESET:
      return {};
    default:
      return state;
  }
};

export const forgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_FORGOT_REQUEST:
      return { loading: true };
    case USER_PASSWORD_FORGOT_SUCCESS:
      return { loading: false, success: true };
    case USER_PASSWORD_FORGOT_FAIL:
      return { loading: false, error: action.payload };
    case USER_PASSWORD_FORGOT_RESET:
      return {};
    default:
      return state;
  }
};

export const passwordResetReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_REQUEST:
      return { loading: true };
    case USER_PASSWORD_RESET_SUCCESS:
      return { loading: false, success: true };
    case USER_PASSWORD_RESET_FAIL:
      return { loading: false, error: action.payload };
    case USER_PASSWORD_RESET_RESET:
      return {};
    default:
      return state;
  }
};

export const emailResendReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESEND_EMAIL_REQUEST:
      return { loading: true };
    case USER_RESEND_EMAIL_SUCCESS:
      return { loading: false, success: true };
    case USER_RESEND_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    case USER_RESEND_EMAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LIST_RESET:
      return { users: [] };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case USER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const userPdfPaymentUrlReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PDF_PAYMENT_URL_REQUEST:
      return { loading: true };
    case USER_PDF_PAYMENT_URL_SUCCESS:
      return { loading: false, url: action.payload };
    case USER_PDF_PAYMENT_URL_FAIL:
      return { loading: false, error: action.payload };
    case USER_PDF_PAYMENT_URL_RESET:
      return {};
    default:
      return state;
  }
};
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import Meta from "../components/Meta";
import { listVehicles } from "../actions/vehicleActions";

export default function FindManualScreen({ history, location }) {
  const pdfpay = location.search
    ? queryString.parse(location.search).pdfpay
    : "";

  const dispatch = useDispatch();

  const [make, setMake] = useState("");
  const [models, setModels] = useState([]);
  const [model, setModel] = useState("");
  const [year, setYear] = useState(0);
  const [category, setCategory] = useState("");
  const [keyword, setKeyword] = useState("");

  //set array for "Select Year" dropdown menu
  const currentYear = new Date().getFullYear();
  const yearSpan = currentYear - 1900;
  const dropdownYears = Array.from(
    new Array(yearSpan),
    (val, index) => currentYear - index,
  );

  const vehicleList = useSelector((state) => state.vehicleList);
  const { loading, error, vehicles } = vehicleList;

  useEffect(() => {
    if (!vehicles) {
      dispatch(listVehicles());
    } else {
      if (make !== "") {
        setModels(
          vehicles.find((vehicle) => vehicle.make === make).models.sort(),
        );
      } else {
        setModels([]);
      }
    }
  }, [dispatch, make, vehicles]);

  const submitHandler = (e) => {
    e.preventDefault();
    history.push(
      `/manuals?make=${make}&model=${model}&year=${year}&category=${category}&keyword=${keyword}`,
    );
  };
  return (
    <FormContainer>
      {pdfpay === "success" && (
        <Message variant="success">
          Thank You for Your payment! For the next 24 hours You can download our
          data in printer friendly PDF format.
        </Message>
      )}
      <h2>Try this:</h2>
      <p>(Mix & Match for better results)</p>
      <Meta
        title="Find Manual | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="make">
          <Form.Control
            as="select"
            value={make}
            onChange={(e) => setMake(e.target.value)}
            className="m-3"
          >
            <option value="">Any Make</option>
            {vehicles &&
              vehicles.map((vehicle) => (
                <option key={vehicle._id} value={vehicle.make}>
                  {vehicle.make}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="model">
          <Form.Control
            as="select"
            value={model}
            onChange={(e) => setModel(e.target.value)}
            className="m-3"
          >
            <option value="">Any Model</option>
            {models &&
              models.map((model, index) => (
                <option key={index} value={model}>
                  {model}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="year">
          <Form.Control
            as="select"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            className="m-3"
          >
            <option value="">Any Year</option>
            {dropdownYears.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="category">
          <Form.Control
            as="select"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="m-3"
          >
            <option value="">Any Category</option>
            <option value="Powertrain">Powertrain</option>
            <option value="Transmission/Clutch/Driveshafts">
              Transmission/Clutch/Driveshafts
            </option>
            <option value="Suspension">Suspension</option>
            <option value="Brakes">Brakes</option>
            <option value="Steering">Steering</option>
            <option value="Body Exterior">Body Exterior</option>
            <option value="Body Interior">Body Interior</option>
            <option value="Electrics/Electronics">Electrics/Electronics</option>
            <option value="Software">Software</option>
            <option value="Heating/Air Conditioning">
              Heating/Air Conditioning
            </option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="keyword">
          <Form.Control
            type="text"
            maxLength={50}
            placeholder="Enter keyword"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type="submit" variant="primary">
          Search
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
          Is your vehicle not on our list? Please,{" "}
          <Link to="/contact">LET US KNOW</Link>
        </Col>
      </Row>
    </FormContainer>
  );
}

export const CONTACT_CREATE_MESSAGE_REQUEST = "CONTACT_CREATE_MESSAGE_REQUEST";
export const CONTACT_CREATE_MESSAGE_SUCCESS = "CONTACT_CREATE_MESSAGE_SUCCESS";
export const CONTACT_CREATE_MESSAGE_FAIL = "CONTACT_CREATE_MESSAGE_FAIL";
export const CONTACT_CREATE_MESSAGE_RESET = "CONTACT_CREATE_MESSAGE_RESET";

export const CONTACT_LIST_REQUEST = "CONTACT_LIST_REQUEST";
export const CONTACT_LIST_SUCCESS = "CONTACT_LIST_SUCCESS";
export const CONTACT_LIST_FAIL = "CONTACT_LIST_FAIL";
export const CONTACT_LIST_RESET = "CONTACT_LIST_RESET";

export const CONTACT_DELETE_REQUEST = "CONTACT_DELETE_REQUEST";
export const CONTACT_DELETE_SUCCESS = "CONTACT_DELETE_SUCCESS";
export const CONTACT_DELETE_FAIL = "CONTACT_DELETE_FAIL";
export const CONTACT_DELETE_RESET = "CONTACT_DELETE_RESET";

export const CONTACT_DETAILS_REQUEST = "CONTACT_DETAILS_REQUEST";
export const CONTACT_DETAILS_SUCCESS = "CONTACT_DETAILS_SUCCESS";
export const CONTACT_DETAILS_FAIL = "CONTACT_DETAILS_FAIL";
export const CONTACT_DETAILS_RESET = "CONTACT_DETAILS_RESET";

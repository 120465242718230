import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default function PaginateContacts({ pages, page }) {
  return (
    pages > 1 && (
      <ButtonGroup className="mb-2">
        <LinkContainer to={"/admin/contactlist?pageNumber=1"}>
          <Button disabled={page === 1}>First Page</Button>
        </LinkContainer>

        <LinkContainer to={`/admin/contactlist?pageNumber=${page - 1}`}>
          <Button disabled={page === 1}>Previous Page</Button>
        </LinkContainer>

        <LinkContainer to={`/admin/contactlist?pageNumber=${page + 1}`}>
          <Button disabled={page === pages}>Next Page</Button>
        </LinkContainer>

        <LinkContainer to={`/admin/contactlist?pageNumber=${pages}`}>
          <Button disabled={page === pages}>Last Page</Button>
        </LinkContainer>
      </ButtonGroup>
    )
  );
}

export const VEHICLE_LIST_REQUEST = "VEHICLE_LIST_REQUEST";
export const VEHICLE_LIST_SUCCESS = "VEHICLE_LIST_SUCCESS";
export const VEHICLE_LIST_FAIL = "VEHICLE_LIST_FAIL";

export const VEHICLE_CREATE_MAKE_REQUEST = "VEHICLE_CREATE_MAKE_REQUEST";
export const VEHICLE_CREATE_MAKE_SUCCESS = "VEHICLE_CREATE_MAKE_SUCCESS";
export const VEHICLE_CREATE_MAKE_FAIL = "VEHICLE_CREATE_MAKE_FAIL";
export const VEHICLE_CREATE_MAKE_RESET = "VEHICLE_CREATE_MAKE_RESET";

export const VEHICLE_CREATE_MODEL_REQUEST = "VEHICLE_CREATE_MODEL_REQUEST";
export const VEHICLE_CREATE_MODEL_SUCCESS = "VEHICLE_CREATE_MODEL_SUCCESS";
export const VEHICLE_CREATE_MODEL_FAIL = "VEHICLE_CREATE_MODEL_FAIL";
export const VEHICLE_CREATE_MODEL_RESET = "VEHICLE_CREATE_MODEL_RESET";

export const VEHICLE_DELETE_MAKE_REQUEST = "VEHICLE_DELETE_MAKE_REQUEST";
export const VEHICLE_DELETE_MAKE_SUCCESS = "VEHICLE_DELETE_MAKE_SUCCESS";
export const VEHICLE_DELETE_MAKE_FAIL = "VEHICLE_DELETE_MAKE_FAIL";
export const VEHICLE_DELETE_MAKE_RESET = "VEHICLE_DELETE_MAKE_RESET";

export const VEHICLE_DELETE_MODEL_REQUEST = "VEHICLE_DELETE_MODEL_REQUEST";
export const VEHICLE_DELETE_MODEL_SUCCESS = "VEHICLE_DELETE_MODEL_SUCCESS";
export const VEHICLE_DELETE_MODEL_FAIL = "VEHICLE_DELETE_MODEL_FAIL";
export const VEHICLE_DELETE_MODEL_RESET = "VEHICLE_DELETE_MODEL_RESET";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default function PaginateManuals({
  pages,
  page,
  user = "",
  make = "",
  model = "",
  year = 0,
  category = "",
  keyword = "",
}) {
  return (
    pages > 1 && (
      <ButtonGroup className="mb-2">
        <LinkContainer
          to={
            !user
              ? make || model || year || category || keyword
                ? `/manuals?make=${make}&model=${model}&year=${year}&category=${category}&keyword=${keyword}&pageNumber=1`
                : `/manuals?pageNumber=1`
              : `profile?pageNumber=1`
          }
        >
          <Button disabled={page === 1}>First Page</Button>
        </LinkContainer>

        <LinkContainer
          to={
            !user
              ? make || model || year || category || keyword
                ? `/manuals?make=${make}&model=${model}&year=${year}&category=${category}&keyword=${keyword}&pageNumber=${
                    page - 1
                  }`
                : `/manuals?pageNumber=${page - 1}`
              : `/profile?pageNumber=${page - 1}`
          }
        >
          <Button disabled={page === 1}>Previous Page</Button>
        </LinkContainer>
        <LinkContainer
          to={
            !user
              ? make || model || year || category || keyword
                ? `/manuals?make=${make}&model=${model}&year=${year}&category=${category}&keyword=${keyword}&pageNumber=${
                    page + 1
                  }`
                : `/manuals?pageNumber=${page + 1}`
              : `/profile?pageNumber=${page + 1}`
          }
        >
          <Button disabled={page === pages}>Next Page</Button>
        </LinkContainer>
        <LinkContainer
          to={
            !user
              ? make || model || year || category || keyword
                ? `/manuals?make=${make}&model=${model}&year=${year}&category=${category}&keyword=${keyword}&pageNumber=${pages}`
                : `/manuals?pageNumber=${pages}`
              : `/profile?pageNumber=${pages}`
          }
        >
          <Button disabled={page === pages}>Last Page</Button>
        </LinkContainer>
      </ButtonGroup>
    )
  );
}

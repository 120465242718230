import {
  VEHICLE_CREATE_MAKE_FAIL,
  VEHICLE_CREATE_MAKE_REQUEST,
  VEHICLE_CREATE_MAKE_RESET,
  VEHICLE_CREATE_MAKE_SUCCESS,
  VEHICLE_CREATE_MODEL_FAIL,
  VEHICLE_CREATE_MODEL_REQUEST,
  VEHICLE_CREATE_MODEL_RESET,
  VEHICLE_CREATE_MODEL_SUCCESS,
  VEHICLE_DELETE_MAKE_FAIL,
  VEHICLE_DELETE_MAKE_REQUEST,
  VEHICLE_DELETE_MAKE_RESET,
  VEHICLE_DELETE_MAKE_SUCCESS,
  VEHICLE_DELETE_MODEL_FAIL,
  VEHICLE_DELETE_MODEL_REQUEST,
  VEHICLE_DELETE_MODEL_RESET,
  VEHICLE_DELETE_MODEL_SUCCESS,
  VEHICLE_LIST_FAIL,
  VEHICLE_LIST_REQUEST,
  VEHICLE_LIST_SUCCESS,
} from "../constants/vehicleConstants";

export const vehicleListReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_LIST_REQUEST:
      return { loading: true };
    case VEHICLE_LIST_SUCCESS:
      return { loading: false, vehicles: action.payload };
    case VEHICLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const vehicleCreateMakeReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_CREATE_MAKE_REQUEST:
      return { loading: true };
    case VEHICLE_CREATE_MAKE_SUCCESS:
      return { loading: false, createdMake: action.payload };
    case VEHICLE_CREATE_MAKE_FAIL:
      return { loading: false, error: action.payload };
    case VEHICLE_CREATE_MAKE_RESET:
      return {};
    default:
      return state;
  }
};

export const vehicleCreateModelReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_CREATE_MODEL_REQUEST:
      return { loading: true };
    case VEHICLE_CREATE_MODEL_SUCCESS:
      return { loading: false, addedModel: action.payload };
    case VEHICLE_CREATE_MODEL_FAIL:
      return { loading: false, error: action.payload };
    case VEHICLE_CREATE_MODEL_RESET:
      return {};
    default:
      return state;
  }
};

export const vehicleDeleteMakeReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_DELETE_MAKE_REQUEST:
      return { loading: true };
    case VEHICLE_DELETE_MAKE_SUCCESS:
      return { loading: false, success: true };
    case VEHICLE_DELETE_MAKE_FAIL:
      return { loading: false, error: action.payload };
    case VEHICLE_DELETE_MAKE_RESET:
      return {};
    default:
      return state;
  }
};

export const vehicleDeleteModelReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_DELETE_MODEL_REQUEST:
      return { loading: true };
    case VEHICLE_DELETE_MODEL_SUCCESS:
      return { loading: false, removedModel: action.payload };
    case VEHICLE_DELETE_MODEL_FAIL:
      return { loading: false, error: action.payload };
    case VEHICLE_DELETE_MODEL_RESET:
      return {};
    default:
      return state;
  }
};
import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

export default function ManualCard({ history, manual }) {
  return (
    <Card className="my-3 p-3 rounded">
      <Card.Header>
        <strong>
          {manual.make} {manual.model}
        </strong>{" "}
        {manual.yearFrom !== 0 && manual.yearFrom}{" "}
        {manual.yearFrom !== 0 && manual.yearTo !== 0 && <span>-</span>}{" "}
        {manual.yearTo !== 0 && manual.yearTo}
      </Card.Header>
      <Card.Img src={manual.titleImage} />
      <Card.Body>
        <Card.Title as="div">
          <strong>{manual.name}</strong>
          <br />
          {manual.details}
        </Card.Title>
        <Card.Text>
          <em>{manual.numImages} Image(s)</em>
        </Card.Text>
        <Card.Text>{manual.description}</Card.Text>
        <Link
          className="btn btn-success float-right"
          to={`/manual/${manual._id}`}
        >
          See this manual
        </Link>
      </Card.Body>
    </Card>
  );
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { getPdfPaymentURL } from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { USER_PDF_PAYMENT_URL_RESET } from "../constants/userConstants";

export default function PdfAccessChoise({ location }) {
  const manualId = location.search ? queryString.parse(location.search).m : "";

  const pdfpay = location.search
    ? queryString.parse(location.search).pdfpay
    : "";

  const dispatch = useDispatch();

  const userPdfPaymentUrl = useSelector((state) => state.userPdfPaymentUrl);
  const { loading, error, url } = userPdfPaymentUrl;

  useEffect(() => {
    if (url) {
      dispatch({ type: USER_PDF_PAYMENT_URL_RESET });
      window.location.href = url.paymentSessionUrl;
    }
  }, [dispatch, url]);

  const redirectToPayment = () => {
    dispatch(getPdfPaymentURL(manualId));
  };

  return (
    <div>
      {pdfpay === "fail" && (
        <Message variant="danger">Payment failed. Please try again</Message>
      )}
      <h2>PDF Access</h2>
      <h5>
        You have <b>2 options</b> of getting access to PDF files:
      </h5>
      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      <ListGroup variant="flush">
        <ListGroup.Item>
          <h5>
            <b>Option 1:</b> Purchase 24 hour access to all PDF files of the
            website for <b>£2.49</b>.<br />
            Please note! If you can't find something you are looking for - it
            will not be available in PDF either. Please check if we have it
            first! By clicking button bellow you agree with our{" "}
            <Link to="/terms-and-conditions">Terms And Conditions</Link>
          </h5>
          <Button variant="info" onClick={() => redirectToPayment()}>
            Buy 24 hour PDF access
          </Button>
        </ListGroup.Item>

        <ListGroup.Item>
          <h5>
            <b>Option 2:</b> Get <b>FREE</b> lifetime access for adding your own
            manual and getting it approved. You'll get a Contributing Member
            status that will lead to some extra bonuses as website develops.
          </h5>
          <Link className="btn btn-success" to={"/updatemanual"}>
            Add new manual
          </Link>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
}

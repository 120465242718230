import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  manualApproveReducer,
  manualCreateReducer,
  manualDeleteReducer,
  manualDeleteReportReducer,
  manualDetailsReducer,
  manualDisapproveReducer,
  manualLinkReducer,
  manualListReducer,
  manualProblemReportReducer,
  manualReportedListReducer,
  manualUnapprovedListReducer,
  manualUpdateReducer,
} from "./reducers/manualReducers";
import {
  emailResendReducer,
  forgotPasswordReducer,
  passwordResetReducer,
  userActivateReducer,
  userDeleteReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
  userPdfPaymentUrlReducer,
  userRegisterReducer,
  userUpdateReducer,
} from "./reducers/userReducers";
import {
  vehicleCreateMakeReducer,
  vehicleCreateModelReducer,
  vehicleDeleteMakeReducer,
  vehicleDeleteModelReducer,
  vehicleListReducer,
} from "./reducers/vehicleReducers";
import {
  contactCreateMessageReducer,
  contactDeleteReducer,
  contactDetailsReducer,
  contactListReducer,
} from "./reducers/contactReducers";

const appReducer = combineReducers({
  manualList: manualListReducer,
  manualUnapprovedList: manualUnapprovedListReducer,
  manualReportedList: manualReportedListReducer,
  manualDetails: manualDetailsReducer,
  manualCreate: manualCreateReducer,
  manualUpdate: manualUpdateReducer,
  manualDelete: manualDeleteReducer,
  manualApprove: manualApproveReducer,
  manualDisapprove: manualDisapproveReducer,
  manualProblemReport: manualProblemReportReducer,
  manualDeleteReport: manualDeleteReportReducer,
  manualLink: manualLinkReducer,
  vehicleList: vehicleListReducer,
  vehicleCreateMake: vehicleCreateMakeReducer,
  vehicleCreateModel: vehicleCreateModelReducer,
  vehicleDeleteMake: vehicleDeleteMakeReducer,
  vehicleDeleteModel: vehicleDeleteModelReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userActivate: userActivateReducer,
  forgotPassword: forgotPasswordReducer,
  passwordReset: passwordResetReducer,
  emailResend: emailResendReducer,
  userDetails: userDetailsReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userPdfPaymentUrl: userPdfPaymentUrlReducer,
  contactCreateMessage: contactCreateMessageReducer,
  contactList: contactListReducer,
  contactDelete: contactDeleteReducer,
  contactDetails: contactDetailsReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  appReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

import axios from "axios";
import {
  CONTACT_CREATE_MESSAGE_FAIL,
  CONTACT_CREATE_MESSAGE_REQUEST,
  CONTACT_CREATE_MESSAGE_SUCCESS,
  CONTACT_DELETE_FAIL,
  CONTACT_DELETE_REQUEST,
  CONTACT_DELETE_SUCCESS,
  CONTACT_DETAILS_FAIL,
  CONTACT_DETAILS_REQUEST,
  CONTACT_DETAILS_SUCCESS,
  CONTACT_LIST_FAIL,
  CONTACT_LIST_REQUEST,
  CONTACT_LIST_SUCCESS,
} from "../constants/contactConstants";

export const createMessage =
  (name, email, message, captcha) => async (dispatch) => {
    dispatch({ type: CONTACT_CREATE_MESSAGE_REQUEST });
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.post(
        "/api/contactzzz",
        { name, email, message, captcha },
        config
      );
      dispatch({ type: CONTACT_CREATE_MESSAGE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CONTACT_CREATE_MESSAGE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listContacts =
  (pageNumber = "") =>
  async (dispatch, getState) => {
    dispatch({ type: CONTACT_LIST_REQUEST });
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/contactzzz?pageNumber=${pageNumber}`,
        config
      );
      dispatch({ type: CONTACT_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CONTACT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteContact = (id) => async (dispatch, getState) => {
  dispatch({ type: CONTACT_DELETE_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/contactzzz/${id}`, config);
    dispatch({ type: CONTACT_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CONTACT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getContact = (id) => async (dispatch, getState) => {
  dispatch({ type: CONTACT_DETAILS_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/contactzzz/${id}`, config);
    dispatch({ type: CONTACT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CONTACT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

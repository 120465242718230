import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Form } from "react-bootstrap";
import queryString from "query-string";
import Message from "../components/Message";
import Loader from "../components/Loader";
import PaginateUsers from "../components/PaginateUsers";
import { deleteUser, listUsers } from "../actions/userActions";
import { USER_DELETE_RESET } from "../constants/userConstants";
import Meta from "../components/Meta";

export default function UserListScreen({ history, location }) {
  const dispatch = useDispatch();

  const urlKeyword = location.search
    ? queryString.parse(location.search).keyword
    : "";
  const urlSearchTerm = location.search
    ? queryString.parse(location.search).searchTerm
    : "";
  const pageNumber = location.search
    ? queryString.parse(location.search).pageNumber
    : 1;

  const [keyword, setKeyword] = useState("");
  const [searchTerm, setSearchTerm] = useState("name");

  const userList = useSelector((state) => state.userList);
  const { loading, error, users, page, pages } = userList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userDelete = useSelector((state) => state.userDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = userDelete;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch({ type: USER_DELETE_RESET });
      dispatch(listUsers(urlKeyword, urlSearchTerm, pageNumber));
    } else {
      history.push("/login");
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    urlKeyword,
    urlSearchTerm,
    pageNumber,
  ]);

  const deleteHandler = (id, name) => {
    if (window.confirm("Delete " + name + "? Are you sure?")) {
      dispatch(deleteUser(id));
    }
  };
  const searchHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      history.push(
        `/admin/userlist?searchTerm=${searchTerm}&keyword=${keyword}&pageNumber=${pageNumber}`
      );
    } else {
      history.push(`/admin/userlist?pageNumber=${pageNumber}`);
    }
    // dispatch search
  };
  return (
    <>
      <Meta
        title="Admin - User List | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      <h1>Users</h1>

      <Form onSubmit={searchHandler} inline>
        <Form.Control
          type="text"
          name="q"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Search users..."
          className="mr-sm-2 ml-sm-5"
        ></Form.Control>
        <Form.Control
          as="select"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="m-3"
        >
          <option value="name">Name</option>
          <option value="email">Email</option>
        </Form.Control>
        <Button type="submit" variant="primary" className="my-3 p-2">
          Search
        </Button>
      </Form>

      {/* List of users */}
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>ACTIVE</th>
                <th>CREATOR</th>
                <th>ADMIN</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.confirmedEmail ? (
                      <i
                        className="fas fa-check"
                        style={{ color: "green" }}
                      ></i>
                    ) : (
                      <i className="fas fa-times" style={{ color: "red" }}></i>
                    )}
                  </td>
                  <td>
                    {user.isCreator ? (
                      <i
                        className="fas fa-check"
                        style={{ color: "green" }}
                      ></i>
                    ) : (
                      <i className="fas fa-times" style={{ color: "red" }}></i>
                    )}
                  </td>
                  <td>
                    {user.isAdmin ? (
                      <i
                        className="fas fa-check"
                        style={{ color: "green" }}
                      ></i>
                    ) : (
                      <i className="fas fa-times" style={{ color: "red" }}></i>
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`/admin/user/${user._id}/edit`}>
                      <Button variant="light" className="btn-sm mx-3 my-2">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant="danger"
                      className="btn-sm mx-3 my-2"
                      onClick={() => deleteHandler(user._id, user.name)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginateUsers
            pages={pages}
            page={page}
            searchTerm={urlSearchTerm}
            keyword={urlKeyword}
          />
        </>
      )}
    </>
  );
}

import axios from "axios";
import {
  VEHICLE_CREATE_MAKE_FAIL,
  VEHICLE_CREATE_MAKE_REQUEST,
  VEHICLE_CREATE_MAKE_SUCCESS,
  VEHICLE_CREATE_MODEL_FAIL,
  VEHICLE_CREATE_MODEL_REQUEST,
  VEHICLE_CREATE_MODEL_SUCCESS,
  VEHICLE_DELETE_MAKE_FAIL,
  VEHICLE_DELETE_MAKE_REQUEST,
  VEHICLE_DELETE_MAKE_SUCCESS,
  VEHICLE_DELETE_MODEL_FAIL,
  VEHICLE_DELETE_MODEL_REQUEST,
  VEHICLE_DELETE_MODEL_SUCCESS,
  VEHICLE_LIST_FAIL,
  VEHICLE_LIST_REQUEST,
  VEHICLE_LIST_SUCCESS,
} from "../constants/vehicleConstants";

export const listVehicles = () => async (dispatch) => {
  dispatch({ type: VEHICLE_LIST_REQUEST });
  try {
    const { data } = await axios.get("/api/vehicles");
    dispatch({ type: VEHICLE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: VEHICLE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createMake = (newMake) => async (dispatch, getState) => {
  dispatch({ type: VEHICLE_CREATE_MAKE_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post("/api/vehicles", { newMake }, config);
    dispatch({ type: VEHICLE_CREATE_MAKE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: VEHICLE_CREATE_MAKE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createModel = (vehicle) => async (dispatch, getState) => {
  dispatch({ type: VEHICLE_CREATE_MODEL_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put("/api/vehicles", vehicle, config);
    dispatch({ type: VEHICLE_CREATE_MODEL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: VEHICLE_CREATE_MODEL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteMake = (id) => async (dispatch, getState) => {
  dispatch({ type: VEHICLE_DELETE_MAKE_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/vehicles/${id}`, config);
    dispatch({ type: VEHICLE_DELETE_MAKE_SUCCESS });
  } catch (error) {
    dispatch({
      type: VEHICLE_DELETE_MAKE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeModel = (makeId, model) => async (dispatch, getState) => {
  dispatch({ type: VEHICLE_DELETE_MODEL_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `/api/vehicles/${makeId}`,
      { model },
      config
    );
    dispatch({ type: VEHICLE_DELETE_MODEL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: VEHICLE_DELETE_MODEL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

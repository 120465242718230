import {
  CONTACT_CREATE_MESSAGE_FAIL,
  CONTACT_CREATE_MESSAGE_REQUEST,
  CONTACT_CREATE_MESSAGE_RESET,
  CONTACT_CREATE_MESSAGE_SUCCESS,
  CONTACT_DELETE_FAIL,
  CONTACT_DELETE_REQUEST,
  CONTACT_DELETE_RESET,
  CONTACT_DELETE_SUCCESS,
  CONTACT_DETAILS_FAIL,
  CONTACT_DETAILS_REQUEST,
  CONTACT_DETAILS_RESET,
  CONTACT_DETAILS_SUCCESS,
  CONTACT_LIST_FAIL,
  CONTACT_LIST_REQUEST,
  CONTACT_LIST_RESET,
  CONTACT_LIST_SUCCESS,
} from "../constants/contactConstants";

export const contactCreateMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_CREATE_MESSAGE_REQUEST:
      return { loading: true };
    case CONTACT_CREATE_MESSAGE_SUCCESS:
      return { loading: false, success: true };
    case CONTACT_CREATE_MESSAGE_FAIL:
      return { loading: false, error: action.payload };
    case CONTACT_CREATE_MESSAGE_RESET:
      return {};
    default:
      return state;
  }
};

export const contactListReducer = (state = { contacts: [] }, action) => {
  switch (action.type) {
    case CONTACT_LIST_REQUEST:
      return { loading: true };
    case CONTACT_LIST_SUCCESS:
      return {
        loading: false,
        contacts: action.payload.contacts,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case CONTACT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case CONTACT_LIST_RESET:
      return { contacts: [] };
    default:
      return state;
  }
};

export const contactDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_DELETE_REQUEST:
      return { loading: true };
    case CONTACT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CONTACT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case CONTACT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const contactDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_DETAILS_REQUEST:
      return { loading: true };
    case CONTACT_DETAILS_SUCCESS:
      return { loading: false, contact: action.payload };
    case CONTACT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CONTACT_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

import React, { useEffect } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button } from "react-bootstrap";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Meta from "../components/Meta";
import { deleteContact, listContacts } from "../actions/contactActions";
import PaginateContacts from "../components/PaginateContacts";
import { CONTACT_DELETE_RESET } from "../constants/contactConstants";
import { LinkContainer } from "react-router-bootstrap";

export default function ContactListScreen({ history, location }) {
  const dispatch = useDispatch();

  const pageNumber = location.search
    ? queryString.parse(location.search).pageNumber
    : 1;

  const contactList = useSelector((state) => state.contactList);
  const { loading, error, contacts, page, pages } = contactList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const contactDelete = useSelector((state) => state.contactDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = contactDelete;

  useEffect(() => {
      dispatch({ type: CONTACT_DELETE_RESET });
      dispatch(listContacts(pageNumber));
  }, [dispatch, history, userInfo, pageNumber, successDelete]);

  const deleteHandler = (id, name) => {
    if (window.confirm("Delete message from " + name + "? Are you sure?")) {
      dispatch(deleteContact(id));
    }
  };

  return (
    <>
      <Meta
        title="Admin - Contact Message List | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      <h3>Contact Messages</h3>

      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>NEW</th>
                <th>NAME/EMAIL</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact) => (
                <tr key={contact._id}>
                  <td>
                    {contact.new ? (
                      <i
                        className="fas fa-check"
                        style={{ color: "green" }}
                      ></i>
                    ) : (
                      <i className="fas fa-minus" style={{ color: "grey" }}></i>
                    )}
                  </td>
                  <LinkContainer
                    to={`/admin/contactdetails/${contact._id}`}
                    style={{ cursor: "pointer" }}
                  >
                    <td>
                      {contact.name}
                      <br />
                      {contact.email}
                    </td>
                  </LinkContainer>
                  <td>
                    <Button
                      variant="danger"
                      className="btn-sm mx-3 my-2"
                      onClick={() => deleteHandler(contact._id, contact.name)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginateContacts pages={pages} page={page} />
        </>
      )}
    </>
  );
}

import {
  MANUAL_APPROVE_FAIL,
  MANUAL_APPROVE_REQUEST,
  MANUAL_APPROVE_RESET,
  MANUAL_APPROVE_SUCCESS,
  MANUAL_CREATE_FAIL,
  MANUAL_CREATE_REQUEST,
  MANUAL_CREATE_RESET,
  MANUAL_CREATE_SUCCESS,
  MANUAL_DELETE_FAIL,
  MANUAL_DELETE_REPORT_FAIL,
  MANUAL_DELETE_REPORT_REQUEST,
  MANUAL_DELETE_REPORT_RESET,
  MANUAL_DELETE_REPORT_SUCCESS,
  MANUAL_DELETE_REQUEST,
  MANUAL_DELETE_RESET,
  MANUAL_DELETE_SUCCESS,
  MANUAL_DETAILS_FAIL,
  MANUAL_DETAILS_REQUEST,
  MANUAL_DETAILS_RESET,
  MANUAL_DETAILS_SUCCESS,
  MANUAL_DISAPPROVE_FAIL,
  MANUAL_DISAPPROVE_REQUEST,
  MANUAL_DISAPPROVE_RESET,
  MANUAL_DISAPPROVE_SUCCESS,
  MANUAL_LINK_META_FAIL,
  MANUAL_LINK_META_REQUEST,
  MANUAL_LINK_META_RESET,
  MANUAL_LINK_META_SUCCESS,
  MANUAL_LIST_FAIL,
  MANUAL_LIST_REQUEST,
  MANUAL_LIST_SUCCESS,
  MANUAL_REPORTED_LIST_FAIL,
  MANUAL_REPORTED_LIST_REQUEST,
  MANUAL_REPORTED_LIST_SUCCESS,
  MANUAL_REPORT_PROBLEM_FAIL,
  MANUAL_REPORT_PROBLEM_REQUEST,
  MANUAL_REPORT_PROBLEM_RESET,
  MANUAL_REPORT_PROBLEM_SUCCESS,
  MANUAL_UNAPPROVED_LIST_FAIL,
  MANUAL_UNAPPROVED_LIST_REQUEST,
  MANUAL_UNAPPROVED_LIST_SUCCESS,
  MANUAL_UPDATE_FAIL,
  MANUAL_UPDATE_REQUEST,
  MANUAL_UPDATE_RESET,
  MANUAL_UPDATE_SUCCESS,
} from "../constants/manualConstants";

export const manualListReducer = (state = { manuals: [] }, action) => {
  switch (action.type) {
    case MANUAL_LIST_REQUEST:
      return { loading: true, manuals: [] };
    case MANUAL_LIST_SUCCESS:
      return {
        loading: false,
        manuals: action.payload.manuals,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case MANUAL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const manualDetailsReducer = (state = { manual: {} }, action) => {
  switch (action.type) {
    case MANUAL_DETAILS_REQUEST:
      return { loading: true };
    case MANUAL_DETAILS_SUCCESS:
      return { loading: false, manual: action.payload };
    case MANUAL_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case MANUAL_DETAILS_RESET:
      return { manual: {} };
    default:
      return state;
  }
};

export const manualCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MANUAL_CREATE_REQUEST:
      return { loading: true };
    case MANUAL_CREATE_SUCCESS:
      return { loading: false, manual: action.payload };
    case MANUAL_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case MANUAL_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const manualUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case MANUAL_UPDATE_REQUEST:
      return { loading: true };
    case MANUAL_UPDATE_SUCCESS:
      return { loading: false, manual: action.payload };
    case MANUAL_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case MANUAL_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const manualDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MANUAL_DELETE_REQUEST:
      return { loading: true };
    case MANUAL_DELETE_SUCCESS:
      return { loading: false, success: true };
    case MANUAL_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case MANUAL_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const manualApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case MANUAL_APPROVE_REQUEST:
      return { loading: true };
    case MANUAL_APPROVE_SUCCESS:
      return { loading: false, success: true };
    case MANUAL_APPROVE_FAIL:
      return { loading: false, error: action.payload };
    case MANUAL_APPROVE_RESET:
      return {};
    default:
      return state;
  }
};

export const manualDisapproveReducer = (state = {}, action) => {
  switch (action.type) {
    case MANUAL_DISAPPROVE_REQUEST:
      return { loading: true };
    case MANUAL_DISAPPROVE_SUCCESS:
      return { loading: false, success: true };
    case MANUAL_DISAPPROVE_FAIL:
      return { loading: false, error: action.payload };
    case MANUAL_DISAPPROVE_RESET:
      return {};
    default:
      return state;
  }
};

export const manualUnapprovedListReducer = (
  state = { manuals: [] },
  action
) => {
  switch (action.type) {
    case MANUAL_UNAPPROVED_LIST_REQUEST:
      return { loading: true, manuals: [] };
    case MANUAL_UNAPPROVED_LIST_SUCCESS:
      return {
        loading: false,
        manuals: action.payload.manuals,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case MANUAL_UNAPPROVED_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const manualProblemReportReducer = (state = {}, action) => {
  switch (action.type) {
    case MANUAL_REPORT_PROBLEM_REQUEST:
      return { loading: true };
    case MANUAL_REPORT_PROBLEM_SUCCESS:
      return { loading: false, success: true };
    case MANUAL_REPORT_PROBLEM_FAIL:
      return { loading: false, error: action.payload };
    case MANUAL_REPORT_PROBLEM_RESET:
      return {};
    default:
      return state;
  }
};

export const manualReportedListReducer = (state = { manuals: [] }, action) => {
  switch (action.type) {
    case MANUAL_REPORTED_LIST_REQUEST:
      return { loading: true, manuals: [] };
    case MANUAL_REPORTED_LIST_SUCCESS:
      return {
        loading: false,
        manuals: action.payload.manuals,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case MANUAL_REPORTED_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const manualDeleteReportReducer = (state = {}, action) => {
  switch (action.type) {
    case MANUAL_DELETE_REPORT_REQUEST:
      return { loading: true };
    case MANUAL_DELETE_REPORT_SUCCESS:
      return { loading: false, success: true };
    case MANUAL_DELETE_REPORT_FAIL:
      return { loading: false, error: action.payload };
    case MANUAL_DELETE_REPORT_RESET:
      return {};
    default:
      return state;
  }
};

export const manualLinkReducer = (state = {}, action) => {
  switch (action.type) {
    case MANUAL_LINK_META_REQUEST:
      return { loading: true };
    case MANUAL_LINK_META_SUCCESS:
      return {
        loading: false,
        linkData: action.payload,
      };
    case MANUAL_LINK_META_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MANUAL_LINK_META_RESET:
      return {};
    default:
      return state;
  }
};

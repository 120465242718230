import axios from "axios";
import {
  CONTACT_DETAILS_RESET,
  CONTACT_LIST_RESET,
} from "../constants/contactConstants";
import {
  USER_ACTIVATE_FAIL,
  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PASSWORD_FORGOT_FAIL,
  USER_PASSWORD_FORGOT_REQUEST,
  USER_PASSWORD_FORGOT_SUCCESS,
  USER_PASSWORD_RESET_FAIL,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PDF_PAYMENT_URL_FAIL,
  USER_PDF_PAYMENT_URL_REQUEST,
  USER_PDF_PAYMENT_URL_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_RESEND_EMAIL_FAIL,
  USER_RESEND_EMAIL_REQUEST,
  USER_RESEND_EMAIL_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
} from "../constants/userConstants";

export const login = (email, password) => async (dispatch) => {
  dispatch({ type: USER_LOGIN_REQUEST });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/users/login",
      { email, password },
      config,
    );
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const refreshUser = (token) => async (dispatch) => {
  //dispatch({ type: USER_LOGIN_REQUEST });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post("/api/users/refresh", { token }, config);
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: USER_LIST_RESET });
  dispatch({ type: CONTACT_LIST_RESET });
  dispatch({ type: CONTACT_DETAILS_RESET });
};

export const register =
  (name, email, password, captcha) => async (dispatch) => {
    dispatch({ type: USER_REGISTER_REQUEST });
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      await axios.post(
        "/api/users",
        { name, email, password, captcha },
        config,
      );
      dispatch({ type: USER_REGISTER_SUCCESS });
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resendActivationEmail = (email) => async (dispatch) => {
  dispatch({ type: USER_RESEND_EMAIL_REQUEST });
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    await axios.post("/api/users/resend-activation", { email }, config);
    dispatch({ type: USER_RESEND_EMAIL_SUCCESS });
  } catch (error) {
    dispatch({
      type: USER_RESEND_EMAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const activateUser = (token) => async (dispatch) => {
  dispatch({ type: USER_ACTIVATE_REQUEST });
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.put("/api/users/activate", { token }, config);
    dispatch({ type: USER_ACTIVATE_SUCCESS, payload: data.name });
  } catch (error) {
    dispatch({
      type: USER_ACTIVATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const requestPasswordReset = (email) => async (dispatch) => {
  dispatch({ type: USER_PASSWORD_FORGOT_REQUEST });
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    await axios.put("/api/users/forgot-password", { email }, config);
    dispatch({ type: USER_PASSWORD_FORGOT_SUCCESS });
  } catch (error) {
    dispatch({
      type: USER_PASSWORD_FORGOT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetPassword = (password, resetToken) => async (dispatch) => {
  dispatch({ type: USER_PASSWORD_RESET_REQUEST });
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const { data } = await axios.put(
      "/api/users/reset-password",
      { password, resetToken },
      config,
    );
    dispatch({ type: USER_PASSWORD_RESET_SUCCESS });
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_PASSWORD_RESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserDetails = (id) => async (dispatch, getState) => {
  dispatch({ type: USER_DETAILS_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/users/${id}`, config);
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listUsers =
  (keyword = "", searchTerm = "", pageNumber = "") =>
  async (dispatch, getState) => {
    dispatch({ type: USER_LIST_REQUEST });
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/users?searchTerm=${searchTerm}&keyword=${keyword}&pageNumber=${pageNumber}`,
        config,
      );
      dispatch({ type: USER_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteUser = (id) => async (dispatch, getState) => {
  dispatch({ type: USER_DELETE_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/users/${id}`, config);
    dispatch({ type: USER_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(`/api/users/${user._id}`, user, config);
    dispatch({ type: USER_UPDATE_SUCCESS });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getPdfPaymentURL = (manualId) => async (dispatch, getState) => {
  dispatch({ type: USER_PDF_PAYMENT_URL_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      "/api/users/create-checkout-session-24hr-pdf-access",
      { manualId },
      config,
    );
    dispatch({ type: USER_PDF_PAYMENT_URL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_PDF_PAYMENT_URL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { register } from "../actions/userActions";
import {
  USER_ACTIVATE_RESET,
  USER_REGISTER_RESET,
} from "../constants/userConstants";
import Meta from "../components/Meta";

export default function LoginScreen({ history }) {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, success } = userRegister;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [captcha, setCaptcha] = useState(null);

  useEffect(() => {
    if (success) {
      dispatch({ type: USER_REGISTER_RESET });
      history.push(`/authentication/activate?email=${email}`);
    }
    if (userInfo) {
      dispatch({ type: USER_ACTIVATE_RESET });
      history.push("/");
    }
  }, [dispatch, email, history, success, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Password and Confirm Password do not match");
    } else {
      dispatch(register(name, email, password, captcha));
    }
  };

  //set reCaptcha value for backend verification
  const captchaHandler = (value) => {
    setCaptcha(value);
  };

  return (
    <FormContainer>
      <h1>Register</h1>
      <Meta
        title="Register | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      {message && <Message variant="danger">{message}</Message>}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name">
          <Form.Label>Name (max 20 characters)</Form.Label>
          <Form.Control
            type="text"
            maxLength={20}
            required
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            required
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>
            Password
            <br />
            (min 8 characters, at least 1: Uppercase, Lowercase and Number)
          </Form.Label>
          <Form.Control
            type="password"
            required
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            required
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="agreeToTerms">
          <Form.Label>
            Accept the{" "}
            <Link to="/terms-and-conditions" target="_blank">
              Terms and Conditions
            </Link>{" "}
            <Form.Check type="checkbox" required />
          </Form.Label>
        </Form.Group>

        <Form.Group controlId="captcha">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={captchaHandler}
          />
        </Form.Group>

        <Button type="submit" variant="primary">
          Register
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
          Already a Member? <Link to="/login">Sign In</Link>
        </Col>
      </Row>
    </FormContainer>
  );
}

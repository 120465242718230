import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { getManual, reportManualProblem } from "../actions/manualActions";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Meta from "../components/Meta";
import { MANUAL_REPORT_PROBLEM_RESET } from "../constants/manualConstants";

export default function ReportProblemScreen({ history, match }) {
  const dispatch = useDispatch();

  const manualId = match.params.id;

  const [report, setReport] = useState("");
  const [captcha, setCaptcha] = useState(null);

  const manualDetails = useSelector((state) => state.manualDetails);
  const { loading: loadingManual, error: errorManual, manual } = manualDetails;
  const manualProblemReport = useSelector((state) => state.manualProblemReport);
  const {
    loading: loadingReport,
    error: errorReport,
    success: successReport,
  } = manualProblemReport;

  useEffect(() => {
    if (successReport) {
      window.alert(
        "Thank You for Your feedback! We will look into it and try to fix it as soon as possible."
      );
      dispatch({ type: MANUAL_REPORT_PROBLEM_RESET });
      history.push(`/manual/${manualId}`);
    }
    dispatch(getManual(manualId));
  }, [dispatch, history, manualId, successReport]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(reportManualProblem(manualId, report, captcha));
  };

  //set reCaptcha value for backend verification
  const captchaHandler = (value) => {
    setCaptcha(value);
  };

  return (
    <FormContainer>
      <Meta
        title="Report a Problem | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      {loadingReport && <Loader />}
      {errorReport && <Message variant="danger">{errorReport}</Message>}
      <h1>Report a Problem</h1>
      {loadingManual ? (
        <Loader />
      ) : errorManual ? (
        <Message variant="danger">{errorManual}</Message>
      ) : (
        manual && (
          <Form onSubmit={submitHandler}>
            <Form.Label>
              Please let us know what is wrong with "{manual.name}"
            </Form.Label>
            <Form.Group controlId="report">
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Describe it here..."
                required
                value={report}
                onChange={(e) => setReport(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="captcha">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={captchaHandler}
              />
            </Form.Group>
            <Link className="btn btn-light" to={`/manual/${manualId}`}>
              Cancel
            </Link>{" "}
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        )
      )}
    </FormContainer>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function TermsScreen() {
  return (
    <div>
      <h1>Terms And Conditions</h1>

      <h4>PRIVACY POLICY</h4>
      <p>
        <strong>by SEQ Legal</strong>
      </p>

      <p>
        We are committed to safeguarding the privacy of our website visitors;
        this policy sets out how we will treat your personal information.
      </p>

      <h3>(1) Credit </h3>

      <p>
        We created this privacy policy with the help of an SEQ Legal form
        available at <a href="http://www.website-law.co.uk">Website Law</a>. The
        template documents supplied by SEQ Legal include a{" "}
        <a href="http://www.website-contracts.co.uk/acatalog/web_marketing_agreement_standard.html">
          precedent web marketing agreement
        </a>
        .
      </p>

      <h3>
        (2) Use of our free online repair and servicing manuals collection
      </h3>

      <p>
        (a) Our instructions for vehicle servicing and repairs are created by
        random users and must be used as a reference only! All actions must be
        performed in accordance with Health and Safety regulations. Please, take
        all safety precautions, and if you're not sure - don't act!
        Administration will not accept any responsibility for damage to you or
        your vehicle.
      </p>

      <p>
        (b) All the information that we have is free to use, that's why
        WheelsAndMotors is positioned as "Free Online Manuals". However, there
        can be charge to get our data in PDF format. This charge is for
        convenience only and does not give access to any additional information.
        There are conditions where user can get free access to PDF format - that
        happens if the user contributed useful content, such as creating new
        manual and getting it approved. No refunds will be given based on - "I
        didn't find manual for my vehicle", please make sure that we have the
        information you are looking for before buying PDF access.
      </p>

      <p>
        (c) Manuals approved by website administartion are not guaranteed to be
        correct. It's a community created content and there is an option to
        report a problem with any manual and get it corrected.
      </p>

      <p>
        (d) Any payments are processed by Stripe checkout system. Information
        about Stripe policy can be found here:
        <br />
        <a href="https://stripe.com/en-gb/checkout/legal">
          https://stripe.com/en-gb/checkout/legal
        </a>
      </p>

      <h3>(3) What information do we collect?</h3>

      <p>
        (a) The only PERSONAL information that visitors provide to us for the
        purpose of registering with us - is the email address.
      </p>

      <p>
        (b) In order to keep statistics we may collect, store and use the
        following kinds of information: about visitors computer and about their
        visits to and use of www.WheelsAndMotors.com (including the IP address,
        geographical location, browser type and version, operating system,
        referral source, length of visit, page views and website navigation).
        Any other information that they choose to send to us and that may be
        used by our analytics program.
      </p>

      <p>
        (c) Any payments are processed by Stripe checkout system. Information
        about Stripe policy can be found here:
        <br />
        <a href="https://stripe.com/en-gb/checkout/legal">
          https://stripe.com/en-gb/checkout/legal
        </a>
      </p>

      <h3 id="cookies">(4) Cookies</h3>

      <p>
        A cookie consists of information sent by a web server to a web browser,
        and stored by the browser. The information is then sent back to the
        server each time the browser requests a page from the server. This
        enables the web server to identify and track the web browser.
      </p>

      <p>
        For example, it's a legal requirement for all websites to ask consent
        for cookies. We use simple program notifying visitor about cookie usage
        every time they visit our website. When you agree to our cookie policy
        and click "Accept Cookies" this type of information (cookie) is stored
        in your browser. It's very short piece of data containing pair of words
        "cookieconsent_status" and "dismiss", our website domain, date of
        consent and expiry date (set for one year). Once this cookie is set - on
        every request from your browser our server will "know" that you have
        agreed to our cookie policy and there is no need to keep notifying you.
        Deleting these cookies is fairly simple, but actual process would depend
        on type of browser you use. Simply Google "how to remove cookies" and
        follow instructions.
      </p>

      <p>
        We also use HTML5 Local Storage to keep visitors logged in whilst they
        navigate the website. We keep login information the on Local Storage to
        log you in automaticly on your next visit from your device. To delete
        this type of cookies - simply log off.
      </p>

      <p>
        We use Google Analytics to analyse the use of www.WheelsAndMotors.com.
        Google Analytics generates statistical and other information about
        website use by means of cookies. The information generated relating to
        our website is used to create reports about the use of the website.
        Google will store this information. Google's privacy policy is available
        at:{" "}
        <a href="http://www.google.com/privacypolicy.html">
          http://www.google.com/privacypolicy.html
        </a>
      </p>

      <p>
        We publish Google Adsense interest-based advertisements on our website.
        These are tailored by Google to reflect visitors interests. Third
        parties may be placing and reading cookies on users browser, or using
        web beacons to collect information, in the course of ads being served on
        our website.
      </p>

      <p>
        Any payments are processed by Stripe checkout system. Information about
        Stripe policy can be found here:
        <br />
        <a href="https://stripe.com/en-gb/checkout/legal">
          https://stripe.com/en-gb/checkout/legal
        </a>
      </p>

      <p>
        {" "}
        Most browsers allow you to reject all cookies, whilst some browsers
        allow you to reject just third party cookies. For example, in Internet
        Explorer you can refuse all cookies by clicking "Tools", "Internet
        Options", "Privacy", and selecting "Block all cookies" using the sliding
        selector. Blocking all cookies will, however, have a negative impact
        upon the usability of many websites, including www.WheelsAndMotors.com
      </p>

      <h3>(5) Using your personal information</h3>

      <p>
        Personal information submitted to us via www.WheelsAndMotors.com will be
        used for the purposes specified in this privacy policy or in relevant
        parts of the website.
      </p>

      <p>We may use visitors information to:</p>

      <p>(a) administer the website</p>

      <p>(b) improve your browsing experience by personalising the website</p>

      <p>(c) enable your use of the services available on the website</p>

      <p>
        (d) send you email notifications which you have specifically requested,
        and we promise not to send visitors marketing or commercial
        communications.
      </p>

      <p>
        (e) deal with enquiries and complaints made by or about visitors
        relating to the website and other uses.
      </p>

      <p>
        Where visitors submit thier personal information (e-mail) to our
        website, we will not publish it anywhere on www.WheelsAndMotors.com
      </p>

      <p>
        www.WheelsAndMotors.com doesn't sell, rent or otherwise share any
        details with any third parties except in the limited circumstances, such
        as when we believe we are required to do so by law.
      </p>

      <h3>(6) Disclosures</h3>

      <p>We may disclose visitors personal information: </p>

      <p>(a) to the extent that we are required to do so by law;</p>

      <p>
        (b) in connection with any legal proceedings or prospective legal
        proceedings;
      </p>

      <p>
        (c) in order to establish, exercise or defend our legal rights
        (including providing information to others for the purposes of fraud
        prevention);
      </p>

      <p>
        (d) to any person who we reasonably believe may apply to a court or
        other competent authority for disclosure of that personal information
        where, in our reasonable opinion, such court or authority would be
        reasonably likely to order disclosure of that personal information.
      </p>

      <p>
        Except as provided in this privacy policy, we will not provide your
        information to third parties.
      </p>

      <h3>(7) International data transfers</h3>

      <p>
        Information that we collect may be stored and processed in and
        transferred between any of the countries in which we operate in order to
        enable us to use the information in accordance with this privacy policy.
      </p>

      <h3>(8) Security of your personal information</h3>

      <p>
        We will take reasonable technical and organisational precautions to
        prevent the loss, misuse or alteration of visitors personal information.{" "}
      </p>

      <p>
        We will store all the personal information you provide on our secure
        (password- and firewall- protected) servers. All electronic transactions
        you make to or receive from us will be encrypted using SSL technology.
      </p>

      <p>
        Of course, data transmission over the internet is inherently insecure,
        and we cannot guarantee the security of data sent over the internet.
      </p>

      <p>
        Visitors are responsible for keeping their password and user details
        confidential. We will not ask you for your password (except when you log
        in to the website).
      </p>

      <h3>(9) Policy amendments</h3>

      <p>
        We may update this privacy policy from time-to-time by posting a new
        version on our website. You should check this page occasionally to
        ensure you are happy with any changes.{" "}
      </p>

      <p>We may also notify you of changes to our privacy policy by email. </p>

      <h3>(10) Third party websites</h3>

      <p>
        The website contains links to other websites. We are not responsible for
        the privacy policies or practices of third party websites.
      </p>

      <h4>POSTING RULES</h4>

      <p>
        By posting here you agree not to breach copyright law. Any posts that we
        believe to be breaching law will be deleted by administration
      </p>
      <p>
        {" "}
        * Please be aware that general vehicle repair data, such as torque
        values, part numbers, or information on how to perform certain work can
        not be copyrighted. Just try not to post images that could be subject to
        copyright or don't just copy-paste something. Do your best to share your
        knowledge and explain yourself.
      </p>
      <p>
        You agree not to post any abusive, obscene, vulgar, slanderous, hateful,
        threatening, sexually-orientated or any other material that may violate
        any laws be it of your country, the country where
        www.WheelsAndMotors.com is hosted or International Law. Doing so may
        lead to you being immediately and permanently banned, with notification
        of your Internet Service Provider if deemed required by us. You agree
        that our moderators have the right to remove, edit, move or close any
        topic at any time should we see fit.
      </p>
      <p>
        {" "}
        As a user you agree to any information you have entered to being stored
        in a database. While personal information will not be disclosed to any
        third party without your consent, Waway Ltd can not be held responsible
        for any hacking attempt that may lead to the data being compromised.
      </p>

      <p>
        You agree not to use this website for posting spam or for trolling to
        direct members of our website.
      </p>

      <p>
        Moderators and Administrators have the final word. If you have a problem
        or a complaint, the appropriate actions will be taken. We reserve the
        right to ban flagrant offenders when deemed necessary, with or without
        prior warning.
      </p>

      <h4>CONTACT</h4>

      <p>
        If you have any questions about this Terms and Conditions or our
        treatment of your personal information, please{" "}
        <Link to="/contact">Contact Us</Link>.
      </p>

      <p>
        Last revised on 22nd of February 2022
        <br />
        Waway LTD &copy; 2011 - {new Date().getFullYear()}
      </p>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Button, Form, ListGroup, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createMake,
  createModel,
  deleteMake,
  listVehicles,
  removeModel,
} from "../actions/vehicleActions";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Meta from "../components/Meta";
import {
  VEHICLE_CREATE_MAKE_RESET,
  VEHICLE_CREATE_MODEL_RESET,
  VEHICLE_DELETE_MAKE_RESET,
  VEHICLE_DELETE_MODEL_RESET,
} from "../constants/vehicleConstants";

export default function VehicleEditScreen() {
  const dispatch = useDispatch();

  const [newMake, setNewMake] = useState("");
  const [newModel, setNewModel] = useState("");
  const [make, setMake] = useState("");
  const [makeId, setMakeId] = useState(null);
  const [models, setModels] = useState([]);

  const vehicleList = useSelector((state) => state.vehicleList);
  const { loading, error, vehicles } = vehicleList;

  const vehicleCreateMake = useSelector((state) => state.vehicleCreateMake);
  const {
    loading: loadingNewMake,
    error: errorNewMake,
    createdMake,
  } = vehicleCreateMake;

  const vehicleCreateModel = useSelector((state) => state.vehicleCreateModel);
  const {
    loading: loadingNewModel,
    error: errorNewModel,
    addedModel,
  } = vehicleCreateModel;

  const vehicleDeleteMake = useSelector((state) => state.vehicleDeleteMake);
  const {
    loading: loadingDeleteMake,
    error: errorDeleteMake,
    success: successDeleteMake,
  } = vehicleDeleteMake;

  const vehicleDeleteModel = useSelector((state) => state.vehicleDeleteModel);
  const {
    loading: loadingDeleteModel,
    error: errorDeleteModel,
    removedModel,
  } = vehicleDeleteModel;

  useEffect(() => {
    if (
      !vehicles ||
      createdMake ||
      addedModel ||
      successDeleteMake ||
      removedModel
    ) {
      dispatch(listVehicles());
      if (createdMake) {
        setMake(createdMake.make);
        setNewMake("");
        setNewModel("");
        dispatch({ type: VEHICLE_CREATE_MAKE_RESET });
      } else if (addedModel) {
        setMake(addedModel.make);
        setNewMake("");
        setNewModel("");
        dispatch({ type: VEHICLE_CREATE_MODEL_RESET });
      } else if (successDeleteMake) {
        setMake("");
        dispatch({ type: VEHICLE_DELETE_MAKE_RESET });
      } else if (removedModel) {
        setMake(removedModel.make);
        dispatch({ type: VEHICLE_DELETE_MODEL_RESET });
      }
    } else {
      if (make !== "") {
        setMakeId(vehicles.find((vehicle) => vehicle.make === make)._id);
        setModels(
          vehicles.find((vehicle) => vehicle.make === make).models.sort()
        );
      } else {
        setModels([]);
      }
    }
  }, [
    dispatch,
    vehicles,
    make,
    createdMake,
    addedModel,
    successDeleteMake,
    removedModel,
  ]);

  const addMakeHandler = (e) => {
    e.preventDefault();
    if (newMake) {
      dispatch(createMake(newMake));
    }
  };
  const addModelHandler = (e) => {
    e.preventDefault();
    if (make && newModel) {
      dispatch(createModel({ make, newModel }));
    }
  };
  const deleteMakeHandler = (makeId) => {
    if (window.confirm("Delete all " + make + "? Are you sure?")) {
      dispatch(deleteMake(makeId));
    }
  };
  const deleteModelHandler = (makeId, model) => {
    if (window.confirm(`Delete ${model} for ${make}? Are you sure?`)) {
      dispatch(removeModel(makeId, model));
    }
  };
  return (
    <>
      <Meta
        title="Admin - Edit Vehicle list | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      <h1>Vehicles</h1>

      <FormContainer>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Form onSubmit={addMakeHandler} inline>
              <Form.Control
                type="text"
                name="new-make"
                value={newMake}
                onChange={(e) => setNewMake(e.target.value)}
                placeholder="Add new make..."
                className="mr-sm-2 ml-sm-5"
              ></Form.Control>
              <Button type="submit" className="m-3">
                Add
              </Button>
            </Form>
            {loadingNewMake && <Loader />}
            {errorNewMake && <Message variant="danger">{errorNewMake}</Message>}
          </ListGroup.Item>

          <ListGroup.Item>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : (
              vehicles && (
                <>
                  <h3>Select vehicle to edit:</h3>
                  <Form.Control
                    as="select"
                    value={make}
                    onChange={(e) => setMake(e.target.value)}
                    className="m-3"
                  >
                    <option value="">Select make</option>
                    {vehicles.map((vehicle) => (
                      <option key={vehicle._id} value={vehicle.make}>
                        {vehicle.make}
                      </option>
                    ))}
                  </Form.Control>
                  {make && (
                    <Form onSubmit={addModelHandler} inline>
                      <Form.Control
                        type="text"
                        name="new-model"
                        value={newModel}
                        onChange={(e) => setNewModel(e.target.value)}
                        placeholder="Add new model..."
                        className="mr-sm-2 ml-sm-5"
                      ></Form.Control>
                      <Button type="submit" className="m-3">
                        Add New Model
                      </Button>
                    </Form>
                  )}
                  {loadingNewModel && <Loader />}
                  {errorNewModel && (
                    <Message variant="danger">{errorNewModel}</Message>
                  )}
                  {loadingDeleteModel && <Loader />}
                  {errorDeleteModel && (
                    <Message variant="danger">{errorDeleteModel}</Message>
                  )}
                  <Table striped bordered hover responsive className="table-sm">
                    <tbody>
                      {models &&
                        models.map((model, index) => (
                          <tr key={index}>
                            <td width="100%">{model}</td>
                            <td>
                              <Button
                                variant="danger"
                                className="btn-sm mx-3 my-2"
                                onClick={() =>
                                  deleteModelHandler(makeId, model)
                                }
                              >
                                <i className="fas fa-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {loadingDeleteMake && <Loader />}
                  {errorDeleteMake && (
                    <Message variant="danger">{errorDeleteMake}</Message>
                  )}
                  {make !== "" && (
                    <Button
                      variant="danger"
                      onClick={() => deleteMakeHandler(makeId)}
                    >
                      Remove all {make}
                    </Button>
                  )}
                </>
              )
            )}
          </ListGroup.Item>
        </ListGroup>
      </FormContainer>
    </>
  );
}

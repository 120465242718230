import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <Container>
        <Row>
          <Col className="text-center py-3">
            <Link to="/contact">Contact</Link> |{" "}
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
            <br />
            Waway LTD &copy; 2011 - {new Date().getFullYear()}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

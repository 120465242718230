import React from "react";
import Meta from "../components/Meta";
import { Col, Image, ListGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function HomeScreen() {
  return (
    <Row>
      <Meta
        title="Home Page | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      <Col md={4}>
        <Image src="/images/logo_white_normal.jpg" alt="logo" width="50%" />
        <h3>Wheels And Motors</h3>
        <p>
          <strong>Mechanics for everyone!</strong>
        </p>
        <p>
          Place for automotive repair DIY enthusiasts.
          <br />
          Find solution or help others by sharing your experience!
        </p>
      </Col>
      <Col md={8}>
        <h5>What would you like to do:</h5>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Link to="/findmanual">
              <h2>
                <i className="fas fa-wrench"></i> Find My Manual
              </h2>
            </Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to="/updatemanual">
              <h2>
                <i className="fas fa-plus"></i> Add New Manual
              </h2>
            </Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to="/about">
              <h2>
                <i className="fas fa-info"></i> Find out How It Works
              </h2>
            </Link>
          </ListGroup.Item>
        </ListGroup>
      </Col>
    </Row>
  );
}

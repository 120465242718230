import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { resendActivationEmail } from "../actions/userActions";
import {
  USER_ACTIVATE_RESET,
  USER_RESEND_EMAIL_RESET,
} from "../constants/userConstants";
import Meta from "../components/Meta";

export default function ResendEmailScreen({ history }) {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const emailResend = useSelector((state) => state.emailResend);
  const { loading, error, success } = emailResend;

  const [email, setEmail] = useState("");

  useEffect(() => {
    if (success) {
      dispatch({ type: USER_RESEND_EMAIL_RESET });
      history.push(`/authentication/activate?email=${email}`);
    }
    if (userInfo) {
      dispatch({ type: USER_ACTIVATE_RESET });
      history.push("/");
    }
  }, [dispatch, email, history, success, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(resendActivationEmail(email));
  };
  return (
    <FormContainer>
      <Meta
        title="Re-send Email | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      <h1>Re-send Email Verification</h1>
      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email">
          <Form.Label>
            Enter you Email Address that you used for registration:
          </Form.Label>
          <Form.Control
            type="email"
            required
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type="submit" variant="primary">
          Send Activation Link
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
          Already a Member? <Link to="/login">Sign In</Link>
        </Col>
      </Row>
    </FormContainer>
  );
}

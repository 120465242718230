import axios from "axios";
import {
  MANUAL_APPROVE_FAIL,
  MANUAL_APPROVE_REQUEST,
  MANUAL_APPROVE_SUCCESS,
  MANUAL_CREATE_FAIL,
  MANUAL_CREATE_REQUEST,
  MANUAL_CREATE_SUCCESS,
  MANUAL_DELETE_FAIL,
  MANUAL_DELETE_REPORT_FAIL,
  MANUAL_DELETE_REPORT_REQUEST,
  MANUAL_DELETE_REPORT_SUCCESS,
  MANUAL_DELETE_REQUEST,
  MANUAL_DELETE_SUCCESS,
  MANUAL_DETAILS_FAIL,
  MANUAL_DETAILS_REQUEST,
  MANUAL_DETAILS_SUCCESS,
  MANUAL_DISAPPROVE_FAIL,
  MANUAL_DISAPPROVE_REQUEST,
  MANUAL_DISAPPROVE_SUCCESS,
  MANUAL_LINK_META_FAIL,
  MANUAL_LINK_META_REQUEST,
  MANUAL_LINK_META_SUCCESS,
  MANUAL_LIST_FAIL,
  MANUAL_LIST_REQUEST,
  MANUAL_LIST_SUCCESS,
  MANUAL_REPORTED_LIST_FAIL,
  MANUAL_REPORTED_LIST_REQUEST,
  MANUAL_REPORTED_LIST_SUCCESS,
  MANUAL_REPORT_PROBLEM_FAIL,
  MANUAL_REPORT_PROBLEM_REQUEST,
  MANUAL_REPORT_PROBLEM_SUCCESS,
  MANUAL_UNAPPROVED_LIST_FAIL,
  MANUAL_UNAPPROVED_LIST_REQUEST,
  MANUAL_UNAPPROVED_LIST_SUCCESS,
  MANUAL_UPDATE_FAIL,
  MANUAL_UPDATE_REQUEST,
  MANUAL_UPDATE_SUCCESS,
} from "../constants/manualConstants";

export const listManuals =
  ({
    user = "",
    make = "",
    model = "",
    year = 0,
    category = "",
    keyword = "",
    pageNumber = "",
  }) =>
  async (dispatch) => {
    dispatch({ type: MANUAL_LIST_REQUEST });
    try {
      const { data } = await axios.get(
        `/api/manuals?user=${user}&make=${make}&model=${model}&year=${year}&category=${category}&keyword=${keyword}&pageNumber=${pageNumber}`
      );
      dispatch({ type: MANUAL_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: MANUAL_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getManual = (id) => async (dispatch) => {
  dispatch({ type: MANUAL_DETAILS_REQUEST });
  try {
    const { data } = await axios.get(`/api/manuals/${id}`);
    dispatch({ type: MANUAL_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MANUAL_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createManual = () => async (dispatch, getState) => {
  dispatch({ type: MANUAL_CREATE_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post("/api/manuals", {}, config);
    dispatch({ type: MANUAL_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MANUAL_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateManual = (manual) => async (dispatch, getState) => {
  dispatch({ type: MANUAL_UPDATE_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `/api/manuals/${manual._id}`,
      manual,
      config
    );
    dispatch({ type: MANUAL_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MANUAL_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listUnapprovedManuals = () => async (dispatch, getState) => {
  dispatch({ type: MANUAL_UNAPPROVED_LIST_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get("/api/manuals/notapproved", config);
    dispatch({ type: MANUAL_UNAPPROVED_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MANUAL_UNAPPROVED_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listReportedManuals = () => async (dispatch, getState) => {
  dispatch({ type: MANUAL_REPORTED_LIST_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get("/api/manuals/reported", config);
    dispatch({ type: MANUAL_REPORTED_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MANUAL_REPORTED_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteManual = (id) => async (dispatch, getState) => {
  dispatch({ type: MANUAL_DELETE_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/manuals/${id}`, config);
    dispatch({ type: MANUAL_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: MANUAL_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const approveManual = (id) => async (dispatch, getState) => {
  dispatch({ type: MANUAL_APPROVE_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.put(`/api/manuals/approve/${id}`, {}, config);
    dispatch({ type: MANUAL_APPROVE_SUCCESS });
  } catch (error) {
    dispatch({
      type: MANUAL_APPROVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const disapproveManual = (id) => async (dispatch, getState) => {
  dispatch({ type: MANUAL_DISAPPROVE_REQUEST });
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.put(`/api/manuals/disapprove/${id}`, {}, config);
    dispatch({ type: MANUAL_DISAPPROVE_SUCCESS });
  } catch (error) {
    dispatch({
      type: MANUAL_DISAPPROVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const reportManualProblem =
  (id, report, captcha) => async (dispatch) => {
    dispatch({ type: MANUAL_REPORT_PROBLEM_REQUEST });
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.put(`/api/manuals/report/${id}`, { report, captcha }, config);
      dispatch({ type: MANUAL_REPORT_PROBLEM_SUCCESS });
    } catch (error) {
      dispatch({
        type: MANUAL_REPORT_PROBLEM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteManualReport =
  (id, report) => async (dispatch, getState) => {
    dispatch({ type: MANUAL_DELETE_REPORT_REQUEST });
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.put(`/api/manuals/deletereport/${id}`, { report }, config);
      dispatch({ type: MANUAL_DELETE_REPORT_SUCCESS });
    } catch (error) {
      dispatch({
        type: MANUAL_DELETE_REPORT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const fetchLinkData = (url) => async (dispatch) => {
  dispatch({ type: MANUAL_LINK_META_REQUEST });
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post("/api/manuals/linkmeta", { url }, config);
    dispatch({ type: MANUAL_LINK_META_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: MANUAL_LINK_META_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

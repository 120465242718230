import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function AboutScreen() {
  return (
    <div>
      <h2>Simple!</h2>

      <p>
        Here we have{" "}
        <strong>
          free online collection of vehicle service and repair manuals
        </strong>
        . It is constantly updated by our members. To access our data you don't
        need to register, you don't need to pay. Simply use information that we
        have. However, some services, such as an access to PDF format, may
        require registration and a small payment; OR instead of payment what we
        would value more - is your input to our content, then as a
        Contributing Member you get benefits described below.
      </p>

      <Image src="images/search.jpg" alt="search" className="centered" />
      <br />

      <p>
        By adding your own manual, you get a status of{" "}
        <strong>Contributing Member</strong> and:{" "}
      </p>
      <ul>
        <li>
          <strong>get FREE lifetime access to all our services</strong>{" "}
        </li>
        <li>
          <strong>promote your own YouTube channel and/or Website</strong>
        </li>
        <li>
          <strong>add good karma for helping other people</strong>
        </li>
      </ul>

      <Image src="images/add_manual.jpg" alt="search" className="centered" />
      <br />

      <p>
        <strong>Promote your YouTube channel and Website!</strong> Another
        reason to post here - boost traffic to your YouTube channel and/or
        Website. When you add your manual, there are fields for links that you
        wish to promote. Our website will prettify them - add thumbnail and meta
        data to make them look more attractive:
      </p>

      <Image src="images/promote.png" alt="search" className="centered" />
      <br />
      <br />

      <p>
        <strong>Registration.</strong> We don't take any personal details for
        registration, except your email, but even your email won't be seen by
        anyone. We are <strong>not going to send you any spam</strong> and we
        are <strong>not sharing your email</strong> with any third parties
        either, except in limited circumstances when we require to do so by law.
      </p>

      <Image src="images/login.jpg" alt="search" className="centered" />
      <br />

      <p>
        Originally this website meant be used as{" "}
        <strong>social network for drivers</strong>, but that part of the
        website wasn't so popular, so we decided - instead of reinventing a
        bycicle, move all "social stuff" to{" "}
        <a
          href="https://www.facebook.com/groups/wheelsandmotors"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "blue" }}
        >
          FACEBOOK GROUP
        </a>{" "}
        and keep this website for the main reason why people come here - repair
        and servicing manuals.
      </p>

      <p>
        That is it pretty much... If you have any questions or suggestions,
        please don't hesitate to contact us <Link to="/contact">here</Link>.
      </p>
    </div>
  );
}

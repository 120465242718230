import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import Meta from "../components/Meta";
import { createMessage } from "../actions/contactActions";
import { CONTACT_CREATE_MESSAGE_RESET } from "../constants/contactConstants";

export default function ContactFormScreen({ history }) {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [captcha, setCaptcha] = useState(null);

  const contactCreateMessage = useSelector(
    (state) => state.contactCreateMessage
  );
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = contactCreateMessage;

  useEffect(() => {
    if (successCreate) {
      window.alert(
        "Thanks! Your message was received. We will respond as soon as we can."
      );
      dispatch({ type: CONTACT_CREATE_MESSAGE_RESET });
      history.push("/");
    }
  }, [dispatch, history, successCreate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createMessage(name, email, message, captcha));
  };

  //set reCaptcha value for backend verification
  const captchaHandler = (value) => {
    setCaptcha(value);
  };
  return (
    <FormContainer>
      <h3>Contact Us</h3>
      <p>
        Please <span style={{ color: "red" }}>DO NOT</span> contact website
        administration in relation to your vehicle{" "}
        <span style={{ color: "red" }}>MECHANICAL</span> issues!
        <br />
        We have a{" "}
        <a
          href="https://www.facebook.com/groups/wheelsandmotors"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "blue" }}
        >
          FACEBOOK GROUP
        </a>{" "}
        - post it there, and we'll be happy to help you, if we can.
        <br />
        Also, you'll be seen by more people, which means more chances to get
        your solution...
        <br />
        Thanks!
      </p>
      <Meta
        title="Contact Us | Wheels And Motors"
        description="Free online automotive repair manuals"
        keywords="car, motorbike, free, online, workshop, repair, manuals"
      />
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant="danger">{errorCreate}</Message>}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name">
          <Form.Label>Your Name</Form.Label>
          <Form.Control
            type="text"
            maxLength={50}
            required
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            required
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="message">
          <Form.Control
            as="textarea"
            rows={8}
            required
            placeholder="Write Your message here"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="captcha">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={captchaHandler}
          />
        </Form.Group>

        <Button type="submit" variant="primary">
          Send
        </Button>
      </Form>
    </FormContainer>
  );
}
